<template>
  <div class="search bgfff dfr">
    <div class="search-box">
      <el-input
        class="w100 h100"
        :placeholder="searchText.placeholder"
        v-model="keyword"
        @keyup.enter.native="search"
      >
      </el-input>
    </div>
    <div
      @click="search"
      class="search-btn fs20 fcfff bgtheme pointer dfr flex-center"
    >
      {{ searchText.buttonText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
    value: {
      type: String,
      default: ""
    },
    searchText: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      keyword: ""
    };
  },
  watch: {
    value(key) {
      if (key) {
        this.keyword = key;
      }
    }
  },
  created() {
    this.initPage();
  },

  mounted() {},
  methods: {
    async initPage() {
      this.keyword = this.value;
    },
    search() {
      this.$emit("search", this.keyword);
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.search {
  width: 760px;
  max-width: 100%;
  height: 66px;
  border-radius: 6px;
  .search-box {
    width: 600px;
    height: 66px;
  }
  .search-btn {
    width: 160px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}
// @media screen and (max-width: 767px) {

// }
</style>
