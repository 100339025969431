<template>
  <el-dialog
    class="dialog-box por agreement-dialog"
    :visible.sync="show"
    width="50%"
    :show-close="false"
    :before-close="handleClose"
  >
    <div class="dialog-content ">
      <div @click="handleClose" class="close-box dfr flex-center pointer">
        <img src="../assets/images/close.png" alt="" class="close" />
      </div>
      <div class="agreement" v-html="agreement"></div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      disabled: true,
      firstName: "",
      lastName: "",
      email: "",
      emailFormatErr: false,
      showCreate: false,
      showPassword: false,
      showSign: false,
      createInfo: null,
      agreement: "",
      lang: "EN"
    };
  },
  watch: {},
  async created() {
    this.initPage();
  },
  mounted() {
    this.$bus.$on("changeLang", () => {
      this.initLang();
      this.initPage();
    });
  },
  methods: {
    initLang() {
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.footer;
      } else if (lang == "CN") {
        this.langText = this.$CN.footer;
      }
      this.lang = lang;
    },
    handleClose() {
      this.$emit("update:show", false);
    },
    async initPage() {
      const resp = await this.$API
        .post("agreement", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        const ENContent = resp.data.agreement[0].en_content || "";
        const CNCOntent = resp.data.agreement[0].content || "";
        let content = ENContent;
        if (this.lang == "CN") {
          content = CNCOntent;
        }
        this.agreement = content;
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="scss">
.dialog-box {
  border-radius: 6px;
  .close-box {
    width: 54px;
    height: 54px;
    position: absolute;
    top: 0;
    right: 0;
    .close {
      width: 14px;
      height: 14px;
    }
  }
}
</style>
