<template>
  <div class="detail-tab-inner  ">
    <div class="title-box fs18 fc333">
      {{ detail.surroundings_describe || "" }}
    </div>
    <div class="surroundings-wrap">
      <div v-if="detail.around_school.length" class=" dfr cross-start">
        <div class="surrounding-item dfr cross-start">
          <div class="icon-box fs0 dfr flex-center">
            <img src="../../assets/images/colleges.png" class=" icon" alt="" />
          </div>
          <div class="content-list-wrap">
            <el-table :data="detail.around_school" style="width: 100%">
              <el-table-column
                prop="school_name"
                class="header-name"
                :label="lang == 'CN' ? '学校' : 'Colleges'"
                width="400"
              >
              </el-table-column>
              <el-table-column
                prop="drive"
                :label="lang == 'CN' ? '驾车' : 'Drive'"
                width="180"
              >
              </el-table-column>
              <el-table-column
                prop="distance"
                :label="lang == 'CN' ? '距离' : 'Distance'"
                width="180"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in surroundings"
        :key="index"
        class=" dfr cross-start"
      >
        <div class="surrounding-item dfr cross-start" v-if="item.list.length">
          <div class="icon-box fs0 dfr flex-center">
            <img :src="item.icon" class=" icon" alt="" />
          </div>
          <div class="content-list-wrap">
            <el-table :data="item.list" style="width: 100%">
              <el-table-column
                prop="name"
                class="header-name"
                :label="item.name"
                width="400"
              >
              </el-table-column>
              <el-table-column prop="date" label="Drive" width="180">
              </el-table-column>
              <el-table-column prop="address" label="Distance" width="180">
              </el-table-column>
            </el-table>
            <!-- <div class="surrounding-title dfr fs18 fc000">
            <div class="s-name fb ">{{ item.name }}</div>
            <div class="s-time">Drive</div>
            <div class="s-dis">Distance</div>
          </div>
          <div
            v-for="(sorround, sindex) in item.list"
            :key="sindex"
            class="surrounding-content dfr"
          >
            <div class="s-name">{{ sorround.name }}</div>
            <div class="s-time">12</div>
            <div class="s-dis">23</div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="markers-title-list dfr flex-wrap ">
      <div
        v-for="(item, index) in markersTitleList"
        :key="index"
        class="markes-title-item fs16 pointer"
        @click="activeTitle(index, item.value)"
        :class="[item.active ? 'active' : '']"
      >
        <span v-if="lang == 'CN'">{{ item.CNName }}</span>
        <span v-else>{{ item.ENName }}</span>
      </div>
    </div>
    <div class="gmap-wrap">
      <div id="map-surrounding"></div>
    </div>
  </div>
</template>

<script>
import MarkerWithLabel from "@googlemaps/markerwithlabel";
export default {
  props: {
    detail: Object,
    lang: String
  },
  data() {
    return {
      surroundings: [
        {
          name: "Transit / Subway",
          icon: require("../../assets/images/subway.png"),
          // https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=38.90710332138825,%20-77.06415906077741&radius=30000&type=subway_station&key=AIzaSyDkjg3cG7o4K5OKWysF6ASAnh2TzHIqoKw
          list: [
            // {
            //   business_status: "OPERATIONAL",
            //   geometry: {
            //     location: {
            //       lat: 38.8958052,
            //       lng: -77.0719079
            //     },
            //     viewport: {
            //       northeast: {
            //         lat: 38.8972716802915,
            //         lng: -77.0704990697085
            //       },
            //       southwest: {
            //         lat: 38.8945737197085,
            //         lng: -77.07319703029151
            //       }
            //     }
            //   },
            //   icon:
            //     "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
            //   name: "罗斯林站",
            //   photos: [
            //     {
            //       height: 4032,
            //       html_attributions: [
            //         '<a href="https://maps.google.com/maps/contrib/104773444981742774015">Shivani K</a>'
            //       ],
            //       photo_reference:
            //         "ATtYBwJ_fVulmIGTgca1SjZFxLWNxK_5tIRTeEhf1EUAq6UYx4dwWArD0AQt1LmkWUF5Ly3XEqDRQmpo7ljHorwV3QQoy1TeXTA8KyX_e0F7xQmIQrTUezvGL71xTkksyNjQMusdX-hAHpzqyrl1oY8XfGhyhMAJLU3ysPZzwkAnnip2iLpt",
            //       width: 3024
            //     }
            //   ],
            //   place_id: "ChIJ-eWk3Fu2t4kRFOAv3n3iy1w",
            //   plus_code: {
            //     compound_code: "VWWH+86 美国弗吉尼亚阿灵顿",
            //     global_code: "87C4VWWH+86"
            //   },
            //   rating: 4,
            //   reference: "ChIJ-eWk3Fu2t4kRFOAv3n3iy1w",
            //   scope: "GOOGLE",
            //   types: [
            //     "subway_station",
            //     "transit_station",
            //     "point_of_interest",
            //     "establishment"
            //   ],
            //   user_ratings_total: 107,
            //   vicinity: "1850 North Moore Street, Arlington"
            // }
            // {
            //   business_status: "OPERATIONAL",
            //   geometry: {
            //     location: {
            //       lat: 38.9008727,
            //       lng: -77.05046059999999
            //     },
            //     viewport: {
            //       northeast: {
            //         lat: 38.9021718802915,
            //         lng: -77.04894191970851
            //       },
            //       southwest: {
            //         lat: 38.89947391970851,
            //         lng: -77.05163988029152
            //       }
            //     }
            //   },
            //   icon:
            //     "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
            //   name: "Foggy Bottom GWU Metrorail Station",
            //   photos: [
            //     {
            //       height: 4000,
            //       html_attributions: [
            //         '<a href="https://maps.google.com/maps/contrib/110959747000387832015">Ben Tanowitz</a>'
            //       ],
            //       photo_reference:
            //         "ATtYBwLs4_Oqn5lpCpPf9IrtmK_QfwNr7On7MXCImpWw4fc1OjqdJKPi-t93myH3RhMEHyH2SEBcTPOckrw6dm3OdLl7pyRL0V7og9TuHBhKvF1SANNdu0p5BBCycStyZCiCaLdDUhjK0gzT7GDIQGbz7aYopXrG3VLNkIdmWx3d4HdyMkqZ",
            //       width: 3000
            //     }
            //   ],
            //   place_id: "ChIJJ8uXerG3t4kR9NxSVjScA1c",
            //   plus_code: {
            //     compound_code: "WW2X+8R 美国哥伦比亚区华盛顿哥伦比亚特区",
            //     global_code: "87C4WW2X+8R"
            //   },
            //   rating: 4.3,
            //   reference: "ChIJJ8uXerG3t4kR9NxSVjScA1c",
            //   scope: "GOOGLE",
            //   types: [
            //     "subway_station",
            //     "transit_station",
            //     "point_of_interest",
            //     "establishment"
            //   ],
            //   user_ratings_total: 80,
            //   vicinity: "2301 I Street Northwest, Washington"
            // },
            // {
            //   business_status: "OPERATIONAL",
            //   geometry: {
            //     location: {
            //       lat: 38.9086635,
            //       lng: -77.0433177
            //     },
            //     viewport: {
            //       northeast: {
            //         lat: 38.91236045000002,
            //         lng: -77.04253464999999
            //       },
            //       southwest: {
            //         lat: 38.90741184999999,
            //         lng: -77.04566684999999
            //       }
            //     }
            //   },
            //   icon:
            //     "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
            //   name: "杜邦圆环站",
            //   photos: [
            //     {
            //       height: 3024,
            //       html_attributions: [
            //         '<a href="https://maps.google.com/maps/contrib/108360724959926209466">Alex Tucker</a>'
            //       ],
            //       photo_reference:
            //         "ATtYBwKR2LyqxrvZfW5SMiLxZB9rXV_34H-1YJPTtMSD79ixHJgBdlT831JIeaSn3xTOQeKVccrBIMjU2tNPb6Z_cDStOymGVnZQvf8urfg1QAiqI4gsDQ9UceIHpG2-qnlkwFc4GGAB6Ap8E9lmxFPGoT39Eb7uAz5LpKwufKIzkMjdl2XS",
            //       width: 4032
            //     }
            //   ],
            //   place_id: "ChIJozECuce3t4kRG0tf2urG_zw",
            //   plus_code: {
            //     compound_code: "WX54+FM 美国哥伦比亚区华盛顿哥伦比亚特区",
            //     global_code: "87C4WX54+FM"
            //   },
            //   rating: 4.3,
            //   reference: "ChIJozECuce3t4kRG0tf2urG_zw",
            //   scope: "GOOGLE",
            //   types: [
            //     "subway_station",
            //     "transit_station",
            //     "point_of_interest",
            //     "establishment"
            //   ],
            //   user_ratings_total: 112,
            //   vicinity: "1525 20th Street Northwest, Washington"
            // }
          ]
        },
        {
          name: "Commuter Rail",
          icon: require("../../assets/images/rail.png"),
          list: []
        },
        {
          name: "Shopping Centers",
          icon: require("../../assets/images/shopping.png"),
          // https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=38.90710332138825,%20-77.06415906077741&radius=1500&type=shopping_mall&key=AIzaSyDkjg3cG7o4K5OKWysF6ASAnh2TzHIqoKw

          list: []
        }
      ],
      markersTitleList: [
        {
          CNName: "学校",
          ENName: "Schools",
          active: false,
          value: "school"
        },
        {
          CNName: "餐馆",
          ENName: "Restaurants",
          active: false,
          value: "restaurant"
        },
        {
          CNName: "杂货店",
          ENName: "Groceries",
          active: false,
          value: "supermarket"
        },
        {
          CNName: "咖啡",
          ENName: "Coffee",
          active: false,
          value: "cafe"
        },
        {
          CNName: "银行",
          ENName: "Banks",
          active: false,
          value: "bank"
        },
        {
          CNName: "商店",
          ENName: "Shops",
          active: false,
          value: "store"
        },
        {
          CNName: "健身房",
          ENName: "Fitness",
          active: false,
          value: "gym"
        }
      ],
      map: null,
      marker: null,
      markers: [],
      apartmentAddress: require("../../assets/images/luoju-address.png")
    };
  },
  created() {
    // this.initPosition();
    // this.initSurrounding();
  },

  mounted() {
    this.$nextTick(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      this.initMap();
    });
  },
  methods: {
    initMap() {
      if (window.google) {
        this.map = new window.google.maps.Map(
          document.getElementById("map-surrounding"),
          {
            center: {
              lat: parseFloat(this.detail.lat),
              lng: parseFloat(this.detail.lnt)
            },
            zoom: 16,
            mapId: "fdc00df0e523c8d2"
          }
        );
        this.addApartmentMarker();
        // this.initMarker();
        // this.initTest();
        // this.calcDirection();
      }
    },
    initCategory(type) {
      const _this = this;
      var request = {
        location: {
          lat: parseFloat(this.detail.lat),
          lng: parseFloat(this.detail.lnt)
        },
        radius: "3000",
        type: [type]
      };
      function callback(results) {
        results.forEach(item => {
          _this.addMarker(item);
        });
      }

      let service = new window.google.maps.places.PlacesService(this.map);

      service.nearbySearch(request, callback);
    },
    addMarker(info) {
      const icon = {
        url: info.icon, // url
        scaledSize: new window.google.maps.Size(30, 30), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0) // anchor
      };
      const _marker = new MarkerWithLabel({
        position: {
          lat: info.geometry.location.lat(),
          lng: info.geometry.location.lng()
        },
        // clickable: true,
        icon,
        map: this.map,
        labelContent: info.name, // can also be HTMLElement
        labelAnchor: new window.google.maps.Point(0, 30),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 1.0 }
      });
      this.markers.push(_marker);
    },
    removeMarkers() {
      this.markers.forEach(item => {
        item.setMap(null);
      });
      this.markers = [];
    },
    addApartmentMarker() {
      const icon = {
        url: this.apartmentAddress, // url
        scaledSize: new window.google.maps.Size(30, 30), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0) // anchor
      };
      new MarkerWithLabel({
        position: {
          lat: parseFloat(this.detail.lat),
          lng: parseFloat(this.detail.lnt)
        },
        // clickable: true,
        icon,
        map: this.map,
        labelContent: this.detail.name, // can also be HTMLElement
        labelAnchor: new window.google.maps.Point(0, 30),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 1.0 }
      });
    },
    initMarker() {
      let surroundings = this.surroundings[0].list;
      surroundings.forEach(item => {
        const icon = {
          url: item.icon, // url
          scaledSize: new window.google.maps.Size(30, 30), // scaled size
          origin: new window.google.maps.Point(0, 0), // origin
          anchor: new window.google.maps.Point(0, 0) // anchor
        };
        new MarkerWithLabel({
          position: item.geometry.location,
          // clickable: true,
          map: this.map,
          icon,
          labelContent: item.name, // can also be HTMLElement
          labelAnchor: new window.google.maps.Point(0, 30),
          labelClass: "labels", // the CSS class for the label
          labelStyle: { opacity: 1.0 }
        });
      });
      // this.marker = new window.google.maps.Marker({
      //   position: { lat: 38.9049052352626, lng: -77.0651368275573 },
      //   map: this.map,
      //   title: "Hello World!"
      // });
      // this.marker.setMap(null);
    },
    activeTitle(index, type) {
      this.markersTitleList.map(item => {
        item.active = false;
        return item;
      });
      this.markersTitleList[index].active = true;
      // marker.setMap(null);
      this.removeMarkers();
      this.initCategory(type);
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.markers-title-list {
  margin-top: 60px;
}
.markes-title-item {
  padding: 9px 25px 10px;
  background: #f8f8f8;
  color: #666;
  margin: 10px 8px 0 0;

  transition: all 0.3s;
  &:hover,
  &.active {
    background: #87bcdc;
    color: #fff;
  }
  &:last-child {
    margin-right: 0;
  }
}
.title-box {
  margin-top: 20px;
}
.detail-tab-inner {
  .title {
    margin-top: 50px;
  }
  .gmap-wrap {
    margin: 18px 0 0;
    background: #eee;
  }
  #map-surrounding {
    width: 800px;
    height: 440px;
  }
  .surroundings-wrap {
    .surrounding-item {
      margin-top: 30px;
      .icon-box {
        width: 24px;
        height: 24px;
        background: #87bcdc;
        border-radius: 50%;
        margin-right: 14px;
        margin-top: 12px;
        .icon {
          width: 16px;
        }
      }
      .content-list-wrap {
        width: 760px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .detail-tab-inner {
    .title {
      margin-top: 20px;
      font-size: 28px;
    }
  }
  .detail-tab-inner {
    .surroundings-wrap {
      .surrounding-item {
        width: 100%;
        .content-list-wrap {
          width: calc(100% - 38px);
        }
      }
    }
  }
}
</style>
