<template>
  <div class="home-popular-wrap  ">
    <div class="popular-wrap dfc cross-center">
      <div class="title fs28 fc000">{{ langText.title }}</div>
      <div class="desc fs14 fc666">
        <!-- {{ langText.desc }} -->
      </div>
      <div class="populars dfr ">
        <div v-for="item in populars" :key="item.id" class="content-item">
          <apartment-card :lang="lang" :info="item"></apartment-card>
        </div>
      </div>
      <div
        @click="more"
        class="more-populars pointer dfc flex-center fs16 fcfff"
      >
        {{ langText.moreText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      populars: [],
      page: 1,
      pageSize: 3,
      langText: {
        title: "Popular Listings",
        desc:
          "Find the latest homes for sale, property news & real estate market data",
        moreText: "More Listings "
      },
      lang: "EN"
    };
  },
  async created() {
    await this.initLang();
    await this.initPage();
  },
  mounted() {
    this.$bus.$on("changeLang", () => {
      this.initLang();
      this.initPage();
    });
  },
  methods: {
    initLang() {
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.home.popular;
      } else if (lang == "CN") {
        this.langText = this.$CN.home.popular;
      }
      this.lang = lang;
    },
    async initPage() {
      this.page = 1;
      this.populars = await this.initList();
    },
    async initList() {
      let list = [];
      const data = {
        page: this.page,
        pageSize: this.pageSize,
        tag: this.lang == "CN" ? 1 : 2
      };
      const resp = await this.$API
        .post("populars", data, false)
        .then(resp => resp);

      if (resp.code == 1000) {
        list = resp.data.list;
      }
      return list;
    },
    async more() {
      this.page++;
      const list = await this.initList();
      if (!list.length) {
        let tip = "no more";
        if (this.lang == "CN") {
          tip = "没有更多";
        }
        this.$message(tip);
      }
      this.populars = [...this.populars, ...list];
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.home-popular-wrap {
  min-height: 908px;
  padding: 80px 0 100px;
  box-sizing: border-box;
  width: 1260px;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  .popular-wrap {
    width: 100%;
    .desc {
      margin: 8px 0 30px;
    }
    .populars {
      min-height: 520px;
      box-sizing: border-box;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      transform: translateX(30px);
      margin: 0 auto;
      .content-item {
        margin-right: 30px;
      }
    }
    .more-populars {
      width: 200px;
      height: 50px;
      background: #87bcdc;
      border-radius: 4px;
      transition: all 0.3s;
      margin-top: 40px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .home-popular-wrap {
    .popular-wrap {
      .desc {
        text-align: center;
      }
      .populars {
        transform: translateX(0);
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .poular-item {
          width: 90%;
          margin: 0 auto 30px !important;
        }
        .content-item {
          margin-right: 0;
          width: 100%;
        }
      }
    }
  }
}
</style>
