<template>
  <el-dialog
    class="dialog-box login-dialog por"
    :visible.sync="show"
    width="504px"
    :show-close="false"
    :before-close="handleClose"
  >
    <div class="dialog-content ">
      <div @click="handleClose" class="close-box dfr flex-center pointer">
        <img src="../../assets/images/close.png" alt="" class="close" />
      </div>
      <create-account
        :langText="langText"
        v-if="showCreate"
        @nextHandle="nextHandle"
        @goSign="signShowHandle"
      ></create-account>
      <create-password
        :langText="langText"
        :showFocus="true"
        type="create"
        v-if="showPassword"
        :createInfo="createInfo"
        @forgetOK="forgetOK"
        @signUpOk="signUpOk"
      ></create-password>
      <sign
        :langText="langText"
        v-if="showSign"
        @loginOk="loginOk"
        @goCreate="accountShowHandle"
        @showForgetPopup="showForgetPopup"
      ></sign>
      <!-- 忘记密码 -->
      <create-password
        :langText="langText"
        :showFocus="true"
        type="forget"
        v-if="showForget"
        :createInfo="createInfo"
        @forgetOK="forgetOK"
        @signUpOk="signUpOk"
      ></create-password>

      <!-- <forget-password
        @nextHandle="nextHandle"
        :langText="langText"
        v-if="showForget"
        @goSign="signShowHandle"
      ></forget-password> -->
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "sign"
    }
  },
  data() {
    return {
      disabled: true,
      firstName: "",
      lastName: "",
      email: "",
      emailFormatErr: false,
      showCreate: false,
      showPassword: false,
      showForget: true,
      showSign: false,
      createInfo: null,
      langText: {
        firstname: "First Name",
        lastname: "Last Name",
        email: "Email Address",
        next: "Next",
        alreadyRegister: "Already registered?",
        goSignIn: "Go to sign in",
        iHaveRead: "I have read and accept the",
        createAccount: "Create an Account",
        orSignWith: "Or Sign with",
        signIn: {
          titel: "Sign In",
          desc: "Start your warm accommodation experience and feel at home"
        },
        signUp: {
          titel: "Create an Account",
          desc: "Start your warm accommodation experience and feel at home"
        },
        policy: "Privacy Policy"
      }
    };
  },
  watch: {
    type(type) {
      if (type) {
        if (type == "sign") {
          this.signShowHandle();
        } else if (type == "account") {
          this.accountShowHandle();
        } else if (type == "password") {
          this.passwordShowHandle();
        }
        this.$emit("typeChange");
      }
    }
  },
  created() {},
  mounted() {
    this.initLang();
    this.$bus.$on("changeLang", () => {
      this.initLang();
    });
  },
  methods: {
    initLang() {
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.login;
      } else if (lang == "CN") {
        this.langText = this.$CN.login;
      }
    },
    handleClose() {
      this.$emit("update:show", false);
    },
    accountShowHandle() {
      this.showCreate = true;
      this.showPassword = false;
      this.showSign = false;
      this.showForget = false;
    },
    passwordShowHandle() {
      this.showCreate = false;
      this.showPassword = true;
      this.showSign = false;
      this.showForget = false;
    },
    signShowHandle() {
      this.showCreate = false;
      this.showPassword = false;
      this.showSign = true;
      this.showForget = false;
    },
    showForgetPopup() {
      this.showCreate = false;
      this.showPassword = false;
      this.showSign = false;
      this.showForget = true;
    },
    forgetOK() {
      this.signShowHandle();
    },
    nextHandle(data) {
      this.createInfo = data;
      this.passwordShowHandle();
    },
    signUpOk() {
      this.handleClose();
      this.$bus.$emit("updateInfo");
    },
    loginOk() {
      this.handleClose();
      this.$bus.$emit("updateInfo");
    }
  },
  destroyed() {}
};
</script>
<style lang="scss">
.dialog-box {
  border-radius: 6px;
  .close-box {
    width: 54px;
    height: 54px;
    position: absolute;
    top: 0;
    right: 0;
    .close {
      width: 14px;
      height: 14px;
    }
  }
}
</style>
