<template>
  <!-- <el-dialog
    class="dialog"
    title=""
    :visible.sync="currentShow"
    @close="closeHandle"
    v-loading="loading"
  > -->
  <div v-loading="loading" class="info-input-max-wrap dfc     ">
    <!-- 主题分类 -->
    <div class="name">{{ langText.categoryName }}</div>
    <div class="input-box">
      <el-select
        class="filter-item w100"
        v-model="category"
        :placeholder="langText.categoryPlaceholderName"
      >
        <el-option
          v-for="item in categorys"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="name">{{ langText.messageName }}</div>
    <!-- 提交消息 -->
    <el-input
      resize="none"
      class="message-input"
      type="textarea"
      autosize
      v-model="message"
      @input="messageBlur"
      :placeholder="langText.messagePlaceholder"
    ></el-input>
    <div class="name">{{ langText.name }}</div>
    <!-- 名字 -->
    <el-input
      v-model.trim="name"
      @input="nameBlur"
      :placeholder="langText.name"
      @keyup.enter.native="sendMessage"
    ></el-input>
    <div class="name">{{ langText.email }}</div>
    <!-- 邮箱 -->
    <el-input
      v-model.trim="email"
      @input="emailBlur"
      :placeholder="langText.email"
      @keyup.enter.native="sendMessage"
    ></el-input>
    <!-- 提交 -->
    <div
      @click="sendMessage"
      class="w100 btn-send pointer dfr flex-center fs16 fcfff"
    >
      {{ langText.title }}
    </div>
  </div>
  <!-- </el-dialog> -->
</template>

<script>
export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      loading: false,
      langText: {
        title: "Send Message",
        messagePlaceholder: "提交的消息",
        name: "name",
        email: "email"
      },
      category: "",
      message: "",
      // ['业务咨询','售后服务','投诉','建议','求职'];
      categorys: [
        {
          value: "0",
          en: "Product & Service",
          cn: "产品与服务",
          label: "产品与服务"
        },
        {
          value: "1",
          en: "After-Sales Service",
          cn: "售后服务",
          label: "售后服务"
        },
        {
          value: "2",
          en: "Complaint",
          cn: "投诉",
          label: "投诉"
        },
        {
          value: "3",
          en: "Suggestion",
          cn: "建议",
          label: "建议"
        },
        {
          value: "4",
          en: "Careers",
          cn: "求职",
          label: "求职"
        },
        {
          value: "5",
          en: "Partnership",
          cn: "合作",
          label: "合作"
        }
      ],
      name: "",
      email: "",
      currentShow: false,
      lang: "EN"
    };
  },
  watch: {
    show(_show) {
      this.currentShow = _show;
    }
  },
  created() {
    let lang = this.$localStore.get("lang");
    if (!lang) {
      this.$localStore.set("lang", "EN");
    }
  },
  mounted() {
    this.initLang();
    this.$bus.$on("changeLang", () => {
      this.initLang();
    });
  },
  methods: {
    initLang() {
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.applySendMessage;
        this.categorys.forEach(item => {
          item.label = item.en;
        });
      } else if (lang == "CN") {
        this.categorys.forEach(item => {
          item.label = item.cn;
        });
        this.langText = this.$CN.applySendMessage;
      }
      this.lang = lang;
    },
    messageBlur() {},
    nameBlur() {},
    emailBlur() {},
    validateEmail(email) {
      // 邮箱验证正则
      var reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      return reg.test(email);
    },
    async sendMessage() {
      let categoryEN = "Please select a subject type";
      let messageEN = "Please enter the message to be sent";
      let nameEN = "Please enter a name";
      let emailEN = "Please enter your email address";
      let successText = "Sent successfully";
      let emailReg = "Please enter the correct email address format";
      if (this.lang == "CN") {
        categoryEN = "请选择分类";
        messageEN = "请输入要发送的消息";
        nameEN = "请输入姓名";
        emailEN = "请输入邮箱";
        successText = "发送成功";
        emailReg = "邮箱格式不正确";
      }
      if (!this.category) {
        this.$message(categoryEN);
        return;
      }
      if (!this.message) {
        this.$message(messageEN);
        return;
      }
      if (!this.name) {
        this.$message(nameEN);
        return;
      }
      if (!this.email) {
        this.$message(emailEN);
        return;
      }
      if (!this.validateEmail(this.email)) {
        this.$message(emailReg);
        return;
      }

      this.loading = true;
      const data = {
        type: this.category,
        username: this.name,
        content: this.message,
        email: this.email
      };
      const resp = await this.$API
        .post("sendMeMessage", data, false)
        .then(resp => resp);

      if (resp.code == 1000) {
        this.$message({
          message: successText,
          type: "success"
        });
        this.loading = false;
        this.category = "";
        this.name = "";
        this.message = "";
        this.email = "";
        this.closeHandle();
      }
    },
    closeHandle() {
      this.$emit("close");
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.info-input-max-wrap {
  width: 100%;
}
.dialog {
  overflow: hidden;
  max-width: 100vw;
}
.btn-send {
  width: 100%;
  height: 42px;
  background: #87bcdc;
  border-radius: 4px;
  transition: all 0.3s;
  opacity: 0.9;
  margin-top: 30px;
  &:hover {
    opacity: 1;
  }
}
.name {
  margin: 10px 0;
}
.message-input {
  max-height: 200px;
  min-height: 40px;
  box-sizing: border-box;
}
</style>
