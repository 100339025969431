<template>
  <div v-if="currentBanners.length" class="banner-wrap por ">
    <el-carousel
      :autoplay="false"
      arrow="always"
      indicator-position="none"
      class="banner-swiper por w100 h100"
      @change="slideChange"
    >
      <el-carousel-item
        class="banner-swiper-slide h100 w100 "
        v-for="(item, index) in currentBanners"
        :key="index"
      >
        <div class="slide-box w100 h100 dfc flex-center por">
          <div v-if="item.video" class="video-box dfr flex-center">
            <transition name="el-fade-in">
              <div
                v-show="item.showIcon"
                @click="playVideo(item, index)"
                class="open-video pointer por w100 h100"
              >
                <img
                  class="play-img"
                  :src="`${item.image}?x-oss-process=style/home_big`"
                  alt=""
                />
                <img
                  class="play-icons pointer"
                  src="../../assets/images/play.png"
                  alt=""
                />
              </div>
            </transition>
            <video
              ref="myVideo"
              :src="item.video"
              controls="controls"
              crossOrigin="anonymous"
              :class="[item.showIcon ? '' : 'open']"
              preload="auto"
              class="video-content video mw100 mh100 "
            ></video>
          </div>

          <img
            v-else
            :src="`${item.image}?x-oss-process=style/home_big`"
            alt=""
            class="bg-img poa object-cover"
          />
          <img
            v-if="!item.video"
            :src="`${item.image}?x-oss-process=style/home_big`"
            class="detail-img"
            alt=""
          />
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    banners: Array
  },
  data() {
    return {
      currentBanners: []
    };
  },
  created() {
    let banners = this.banners;
    banners.forEach(item => {
      item.showIcon = true;
      item.isIframe = false;
    });
    this.currentBanners = banners;
  },
  mounted() {},
  methods: {
    playVideo(item, index) {
      this.currentBanners.forEach((item, _index) => {
        if (index == _index) {
          item.showIcon = false;
          if (this.$refs.myVideo[0]) {
            var playPromise = this.$refs.myVideo[0].play();
            if (playPromise !== undefined) {
              playPromise
                .then(function() {
                  // Automatic playback started!
                })
                .catch(function(error) {
                  console.error(error);
                  // Automatic playback failed.
                  // Show a UI element to let the user manually start playback.
                });
            }
          }
        } else {
          item.showIcon = true;
          if (this.$refs.myVideo[0]) {
            this.$refs.myVideo[0].pause();
          }
        }
        this.$set(this.currentBanners, _index, item);
      });
    },
    slideChange() {
      this.currentBanners.forEach((item, _index) => {
        item.showIcon = true;
        if (this.$refs.myVideo[0]) {
          this.$refs.myVideo[0].pause();
        }
        this.$set(this.currentBanners, _index, item);
      });
    },
    preSwiper() {
      this.swiper.slidePrev();
    },
    nextSwiper() {
      this.swiper.slideNext();
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.banner-wrap {
  width: 100%;
  height: 556px;
  &:hover {
    .slide-btn {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }
  .banner-swiper-slide {
    width: 100%;
    height: 556px;
  }
}
.slide-btn {
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.5s;
  color: rgba(255, 255, 255, 0.5);
  z-index: 2;
  box-sizing: border-box;
  font-size: 26px;
  &.left-btn {
    left: 40px;
  }
  &.right-btn {
    right: 40px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.7) !important;
    color: #fff;
  }
}
.video-content.video {
  opacity: 0;
  transition: all 0.3s;
}
.video-content.video.open {
  opacity: 1;
}
.video-box {
  width: 100%;
  height: 100%;
  .open-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .play-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .play-icons {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}
.open-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .banner-wrap {
    height: 260px;
    .banner-swiper-slide {
      width: 100%;
      height: 260px;
    }
  }
  .slide-btn {
    width: 30px;
    height: 30px;

    background: rgba(0, 0, 0, 0.7) !important;
    color: #fff;

    font-size: 20px;
    &.left-btn {
      left: 20px;
    }
    &.right-btn {
      right: 20px;
    }
  }
}
.detail-img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}
.bg-img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(9px);
}
</style>
