<template>
  <!-- @keyup.enter.native="" -->
  <div class="me-account-wrap w100">
    <div class="title mhide dfr cross-center fs16 fc000">
      {{ langText.contactUs.title }}
    </div>
    <div class="account-info-wrap">
      <!-- 发送消息 -->
      <div class="inner-title-wrap dfr    cross-center">
        <div class="line"></div>
        <div class="inner-title fs18 fc000 fb">
          {{ langText.contactUs.sendMessage }}
        </div>
      </div>
      <div class="info-message-dialog-w dfr  mdfc fs14  cross-center ">
        <message-dialog></message-dialog>
      </div>

      <!-- 联系方式 -->
      <div class="inner-title-wrap dfr  margin  cross-center">
        <div class="line"></div>
        <div class="inner-title fs18 fc000 fb">
          {{ langText.contactUs.contactInformation }}
        </div>
      </div>

      <div class="contact-box-wrap">
        <div class="contact-box fs16 fb">{{ langText.contactUs.email }}</div>
        <a class="fs18 fctheme can-hover  " :href="`mailto:${email}`">{{
          email
        }}</a>
      </div>

      <!-- <div class="contact-box-wrap">
        <div class="contact-box fs16 fb">{{ langText.contactUs.tel }}</div>

        <a class="can-hover fs18 fctheme  " :href="`tel:${tel}`"> {{ tel }}</a>
      </div> -->
      <div class="contact-box-wrap">
        <div class="contact-box fs16 fb">
          {{ langText.contactUs.wxService }}
        </div>

        <div class="fs18 fctheme  ">AllsetHome_001</div>
      </div>
      <div class="contact-box-wrap">
        <div class="contact-box fs16 fb">
          {{ langText.contactUs.customerWx }}
        </div>
        <div class="contact-wx fs0">
          <img
            src="../../assets/images/wechat-qrcode.jpeg"
            class="w100 h100"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      langText: {
        title: "My Account",
        personalInformation: {
          topic: "Personal Information",
          firstname: "First Name",
          lastname: "Last Name"
        },
        address: {
          topic: "address",
          line1: "Address Line1",
          line2: "Address Line2",
          city: "City",
          state: "State",
          zipCode: "Zip Code"
        },
        phone: {
          topic: "Phone",
          phoneTitle: "Mobile Phone"
        }
      },
      email: "",
      tel: ""
    };
  },
  async created() {
    await this.initLang();
    await this.initPage();
  },
  mounted() {
    this.$bus.$on("changeLang", () => {
      this.initLang();
      this.initPage();
    });
  },
  methods: {
    initPage() {
      this.initContacts();
    },
    async initContacts() {
      const resp = await this.$API
        .get("contacts", "", false)
        .then(resp => resp);

      if (resp.code == 1000) {
        const info = resp.data.contact[0];
        if (info) {
          this.tel = info.tel;
          this.email = info.email;
        }
      }
    },
    initLang() {
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.me;
      } else if (lang == "CN") {
        this.langText = this.$CN.me;
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.me-account-wrap {
  .title {
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    padding: 0 30px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 6px;
  }
  .account-info-wrap {
    padding: 30px;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    .info-input-max-wrap {
      margin-top: 30px;
      .right-input-box {
        .first {
          margin-right: 30px;
        }
        .right-inner-box {
          width: 165px;
          height: 40px;
          margin-top: 8px;
          border-radius: 6px;
        }
      }
    }
    .left-input-box {
      margin-right: 30px;
    }
    .cell-input-box {
      width: 360px;
      height: 42px;
      margin-top: 8px;
    }
    .inner-title-wrap {
      &.margin {
        margin-top: 50px;
      }
      .line {
        width: 3px;
        height: 16px;
        background: #87bcdc;
        margin-right: 12px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .account-info-wrap {
    margin-top: -80px;
  }
  .me-account-wrap {
    .title {
      padding: 30px 20px;
    }
    .account-info-wrap {
      padding: 30px 20px;
    }
    .info-input-max-wrap {
      width: 100%;
    }
    .left-input-box {
      width: 100%;
      margin-right: 0 !important;
      margin-bottom: 20px;
      .cell-input-box {
        width: 100%;
      }
    }
    .right-input-box {
      width: 100%;
      flex-direction: column;
      .cell-input-box {
        width: 100%;
      }
      .first,
      .second {
        margin-right: 0 !important;
        .right-inner-box {
          width: 100% !important;
          .filter-item {
            width: 100%;
          }
        }
      }
      .second {
        margin-top: 20px;
      }
    }
  }
}
.info-message-dialog-w {
  width: 300px;
}
.contact-box-wrap {
  margin: 20px 0;
}
.contact-box {
  margin-bottom: 10px;
}
.contact-wx {
  width: 160px;
  height: 160px;
  transform: translateX(-6px);
}
</style>
