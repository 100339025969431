<template>
  <div class="me-detail-wrap">
    <div
      v-for="(item, index) in orderList"
      :key="index"
      class="detail-card-wrap"
    >
      <detail-card :detail="item"></detail-card>
    </div>

    <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handleSizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      page: 1,
      pageSize: 5,
      orderList: [],
      total: 0
    };
  },
  created() {
    this.initOrder();
  },
  mounted() {
    // this.initOrder();
  },
  methods: {
    async initOrder() {
      const data = {
        page: this.page,
        pageSize: this.pageSize,
        status: this.status
      };

      const resp = await this.$API.post("orderList", data).then(resp => resp);

      if (resp.code == 1000) {
        this.total = resp.data.total;
        this.orderList = resp.data.list;
      }
      // if (resp.code == 1000) {
      //   this.total = resp.data.total;
      //   this.orderList = resp.data.list;
      // }
    },
    handleSizeChange(e) {
      this.page = e;
      this.initOrder();
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.pagination {
  margin: 20px auto 40px;
}
</style>
