import Vue from "vue";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
locale.use(lang);
import {
  Message,
  Button,
  Loading,
  Col,
  Row,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Pagination,
  DatePicker,
  Select,
  Option,
  Switch,
  Tabs,
  TabPane,
  Dialog,
  Divider,
  Table,
  TableColumn,
  Checkbox,
  Carousel,
  CarouselItem
} from "element-ui";
import "./element-variables.scss";
Vue.use(Button)
  .use(Loading)
  .use(Col)
  .use(Row)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Input)
  .use(Pagination)
  .use(Select)
  .use(Option)
  .use(Switch)
  .use(Tabs)
  .use(TabPane)
  .use(Dialog)
  .use(Divider)
  .use(Table)
  .use(TableColumn)
  .use(Checkbox)
  .use(Carousel)
  .use(CarouselItem)
  .use(DatePicker);
Vue.prototype.$message = Message;
