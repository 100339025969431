<template>
  <div class="create-account-wrap">
    <div class="title fs30 fc000 text-center fb">
      {{ langText.forget.title }}
    </div>
    <div class="desc fs14 fc999 text-center">
      {{ langText.desc }}
    </div>
    <!-- email -->
    <div class=" email-wrap first">
      <div class="fs14 fc333">{{ langText.email }}</div>
      <div class="email-box dfr cross-center por">
        <div class="icon-box dfr flex-center">
          <img
            class="w100  object-cover"
            src="../../assets/images/message.png"
            alt=""
          />
        </div>
        <el-input
          name="email"
          v-model.trim="email"
          @input="emailBlur"
          :placeholder="langText.email"
          @keyup.enter.native="signIn"
        ></el-input>
      </div>
    </div>
    <!-- 邮箱验证码 -->
    <div class=" email-wrap  ">
      <div class="fs14 fc333">{{ langText.forget.emailCode }}</div>
      <div class="email-code-wrap dfr cross-center main-between">
        <div class="email-box email-code dfr cross-center por">
          <div class="icon-box dfr flex-center">
            <img
              class="w100  object-cover"
              src="../../assets/images/email-code.png"
              alt=""
            />
          </div>
          <el-input
            name="code"
            v-model.trim="code"
            @input="codeBlur"
            :placeholder="langText.forget.emailCodePlaceholder"
            @keyup.enter.native="signIn"
          ></el-input>
        </div>
        <div class="email-btn-box ">
          <el-button
            v-if="isCanSend"
            :disabled="email ? false : true"
            class=" h100"
            @click="sendCode"
            >{{ langText.forget.sendCode }}</el-button
          >
          <el-button v-else disabled class=" h100"
            >{{ langText.forget.repeat }}{{ CD }}s
          </el-button>
        </div>
      </div>
    </div>
    <!-- signIn -->
    <div class="next" @click="signIn">
      <el-button class="w100 h100" type="primary" :disabled="disabled">{{
        langText.next
      }}</el-button>
    </div>
    <!-- register -->
    <div class="register-wrap dfr">
      <div class="fs14 fcbbb">{{ langText.alreadyRegister }}</div>
      <div class="go-login pointer" @click="goCreate">
        {{ langText.goSignIn }}
      </div>
    </div>
    <!-- <el-divider class="fcbbb divider" content-position="center">{{
      langText.orSignWith
    }}</el-divider> -->
    <!-- google facebook -->
    <!-- <div class="login-other-wrap dfr cross-center main-between">
      <div
        v-google-signin-button="clientId"
        class="login-inner-box dfr flex-center pointer"
      >
        <img src="../../assets/images/google.png" alt="" class="google" />
        <span class="fs14 fc333 fb">Google</span>
      </div>
      <div class="login-inner-box dfr flex-center pointer">
        <img
          src="../../assets/images/facebook-active.png"
          alt=""
          class="facebook"
        />
        <span class="fs14 fc333 fb">Facebook</span>
      </div>
    </div> -->
  </div>
</template>
<script>
import "vue-google-signin-button-directive";
// import jsonwebtoken from "jsonwebtoken";

export default {
  props: {
    show: Boolean,
    langText: Object
  },
  data() {
    return {
      disabled: true,
      email: "",
      checked: false,
      isCanSend: true,
      code: "",
      CD: 60,
      CDTimer: null,
      clientId:
        "756107412021-t4sfmicdeopje93g06eoropfqf95g1ms.apps.googleusercontent.com"
    };
  },

  created() {},
  mounted() {},
  methods: {
    OnGoogleAuthSuccess(idToken) {
      // 请求后端接口，对登录信息进行校验
      this.GoogleLoginWithIdToken(idToken);
    },
    // OnGoogleAuthFail(error) {},
    // async GoogleLoginWithIdToken(idToken) {
    // let info = jsonwebtoken.decode(idToken);

    // let res = await this.GoogleLoginWithIdToken(idToken);
    // if (res.code === 0) {
    //
    // }
    // },
    // OnGoogleAuthSuccess(idToken) {
    //    //返回第三方结果信息 默认是全token 要用jsonwebtoken 解析
    //
    //   // Receive the idToken and make your magic with the backend
    // },
    emailBlur() {
      this.checkAllFill();
    },
    codeBlur() {
      this.checkAllFill();
    },

    checkChange() {
      this.checkAllFill();
    },
    goCreate() {
      this.$emit("goSign");
    },
    async sendCode() {
      this.CD = 60;
      this.CDTimer = setInterval(() => {
        if (this.CD <= 0) {
          this.CDTimer = null;
          clearInterval(this.CDTimer);
          this.isCanSend = true;
          return;
        }
        this.isCanSend = false;
        this.CD--;
      }, 1000);
      const resp = await this.$API
        .post("emailSend", { email: this.email }, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$message({
          type: "success",
          message: this.langText.sendCodeSuccess
        });
      }
    },

    checkAllFill() {
      if (this.email && this.code) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    async signIn() {
      if (!this.email) {
        return;
      }
      if (!this.code) {
        return;
      }
      const data = {
        email: this.email,
        code: this.code,
        type: "forget"
      };

      this.$emit("nextHandle", data);
    }
  },
  destroyed() {}
};
</script>
<style lang="scss">
.forget-password {
  text-decoration: underline;
}
.create-account-wrap {
  .desc {
    margin-top: 6px;
  }
  .user-name-box {
    margin: 50px 0 0;
    .name-box {
      &.first {
        margin-right: 12px;
      }
      .name {
        width: 197px;
        height: 50px;
        box-sizing: border-box;
        margin-top: 12px;
      }
      .icon-box {
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        margin-right: 18px;
        z-index: 2;
      }
    }
  }
  .email-wrap {
    margin: 20px 0 0;
    .email-code-wrap {
      margin-top: 12px;
      .email-btn-box {
        height: 50px;
      }
    }
    &.first {
      margin-top: 50px;
    }
    .email-box {
      width: 406px;
      height: 50px;
      box-sizing: border-box;
      margin-top: 12px;

      &.email-code {
        margin-top: 0;
        margin-right: 20px;
      }
    }
    .icon-box {
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      margin-right: 18px;
      z-index: 2;
    }
  }
  .emailFormatErr {
    color: #fb7373;
    margin-top: 8px;
  }
  .next {
    width: 406px;
    height: 50px;
    margin-top: 20px;
  }
  .register-wrap {
    margin-top: 20px;
    .go-login {
      color: #4e748b;
      opacity: 0.8;
      text-decoration: underline;
      margin-left: 8px;
      &:hover {
        opacity: 1;
      }
    }
  }
  .divider {
    margin-top: 40px;
  }
  .login-other-wrap {
    margin-top: 20px;
    .login-inner-box {
      width: 197px;
      height: 50px;
      border-radius: 6px;
      border: 1px solid #eaeaea;
      transition: all 0.3s;
      &:hover {
        border-color: #87bcdc;
      }
      .google {
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }
      .facebook {
        width: 12px;
        height: 20px;
        margin-right: 20px;
      }
    }
  }
}
</style>
