<template>
  <div class="home-bg-wrap  por dfc cross-center">
    <img src="../../assets/images/home-bg.png" alt="" class="bg" />
    <div class="content-box por dfc cross-center  ">
      <div class="title fs16 fcfff op8">{{ lineInfo.lineF }}</div>
      <div class="desc fs60 fcfff text-center ">{{ lineInfo.lineS }}</div>
      <div class="desc-s fs16 fcfff text-center op7">
        {{ lineInfo.lineT }}
      </div>
      <apartment-search
        :searchText="langText.search"
        @search="search"
      ></apartment-search>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: "",
      lang: "EN",
      lineInfo: {
        lineF: "WELCOME TO",
        lineS: "Settle Down Apartment",
        lineT:
          "We are committed to apartment leasing, a professional platform for international students to rent houses"
      },
      langText: {
        search: {
          placeholder: "Apartment Name or Address",
          buttonText: "Search"
        }
      }
    };
  },
  created() {
    this.initLang();
    this.initPage();
  },
  mounted() {
    this.$bus.$on("changeLang", () => {
      this.initLang();
      this.initPage();
    });
  },
  methods: {
    initLang() {
      let lang = this.$localStore.get("lang");
      this.lang = lang;
      if (lang == "EN") {
        this.langText = this.$EN.home.banner;
      } else if (lang == "CN") {
        this.langText = this.$CN.home.banner;
      }
    },
    async initPage() {
      const resp = await this.$API
        .post("homeWord", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        if (this.lang == "CN") {
          this.lineInfo.lineF = resp.data.home_word[0].first_line;
          this.lineInfo.lineS = resp.data.home_word[0].second_line;
          this.lineInfo.lineT = resp.data.home_word[0].third_line;
        } else {
          this.lineInfo.lineF = resp.data.home_word[0].first_line_en;
          this.lineInfo.lineS = resp.data.home_word[0].second_line_en;
          this.lineInfo.lineT = resp.data.home_word[0].third_line_en;
        }
      }
    },
    async search(key) {
      let filterData = this.$localStore.get("filterData") || "";
      if (!filterData) {
        this.saveStoreFilter();
      } else {
        filterData.keyword = key;
        this.$localStore.set("filterData", filterData);
      }
      this.$go("apartmentList");
    },
    saveStoreFilter() {
      const data = {
        keyword: "",
        minPrice: "",
        maxPrice: "",
        currentBeds: "",
        distanceSort: 2,
        unShowMap: true,
        site: ""
      };
      this.$localStore.set("filterData", data);
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.home-bg-wrap {
  min-height: 556px;
  width: 100%;
  .bg {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content-box {
    max-width: 90%;
    z-index: 2;
    padding-bottom: 100px;
    .title {
      margin-top: 130px;
    }
    .desc {
      margin: 12px 0 40px;
    }
    .desc-s {
      margin-bottom: 100px;
    }
  }
}
// @media screen and (max-width: 767px) {

// }
</style>
