<template>
  <div class="create-account-wrap">
    <div class="title fs30 fc000 text-center fb">
      {{ langText.signUp.title }}
    </div>
    <div class="desc fs14 fc999 text-center">
      {{ langText.signUp.desc }}
    </div>
    <!-- name -->
    <div class="user-name-box dfr mdfc fs14 fc333">
      <div class="name-box first">
        <div>{{ langText.firstname }}</div>
        <div class="name dfr  cross-center por">
          <div class="icon-box dfr cross-center ">
            <img class="w100" src="../../assets/images/user.png" alt="" />
          </div>
          <el-input
            name="name"
            v-model.trim="firstName"
            @input="firstNameBlur"
            :placeholder="langText.firstname"
            @keyup.enter.native="canNext"
          ></el-input>
        </div>
      </div>
      <div class="last-name">
        <div class="name-box ">
          <div>{{ langText.lastname }}</div>
          <div class="name dfr cross-center por">
            <div class="icon-box dfr flex-center">
              <img class="w100 " src="../../assets/images/user.png" alt="" />
            </div>
            <el-input
              name="name"
              v-model.trim="lastName"
              @input="lastNameBlur"
              :placeholder="langText.firstname"
              @keyup.enter.native="canNext"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="tip fs14 fc999">{{ langText.tipName }}</div>

    <!-- tel -->
    <div class="email-wrap">
      <div class="fs14 fc333">{{ langText.tel }}</div>
      <div class="email-box dfr cross-center por">
        <div class="icon-box dfr flex-center">
          <img
            class="w100  object-cover"
            src="../../assets/images/regist-tel.png"
            alt=""
          />
        </div>
        <el-input
          name="tel"
          v-model.trim="tel"
          :placeholder="langText.tel"
          @keyup.enter.native="canNext"
        ></el-input>
      </div>
    </div>
    <!-- next -->
    <div class="next" @click="canNext">
      <el-button class="w100 h100" type="primary" :disabled="disabled">{{
        langText.next
      }}</el-button>
    </div>
    <!-- register -->
    <div class="register-wrap dfr">
      <div class="fs14 fcbbb">{{ langText.alreadyRegister }}</div>
      <div class="go-login pointer" @click="goSign">
        {{ langText.goSignIn }}
      </div>
    </div>
    <!-- <el-divider class="fcbbb divider" content-position="center">{{
      langText.orSignWith
    }}</el-divider> -->
    <!-- google facebook -->
    <!-- <div class="login-other-wrap dfr cross-center main-between">
      <div class="login-inner-box dfr flex-center pointer">
        <img src="../../assets/images/google.png" alt="" class="google" />
        <span class="fs14 fc333 fb">Google</span>
      </div>
      <div class="login-inner-box dfr flex-center pointer">
        <img
          src="../../assets/images/facebook-active.png"
          alt=""
          class="facebook"
        />
        <span class="fs14 fc333 fb">Facebook</span>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  props: {
    show: Boolean,
    langText: Object
  },
  data() {
    return {
      disabled: true,
      firstName: "",
      lastName: "",
      tel: ""
    };
  },

  created() {},
  mounted() {},
  methods: {
    firstNameBlur() {
      // this.email

      this.checkAllFill();
    },
    lastNameBlur() {
      this.checkAllFill();
    },

    canNext() {
      if (!this.firstName) {
        return;
      }
      if (!this.lastName) {
        return;
      }

      const data = {
        first_name: this.firstName,
        last_name: this.lastName,
        tel: this.tel,
        type: "regist"
      };
      this.$emit("nextHandle", data);
    },

    checkAllFill() {
      if (this.firstName && this.lastName) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    goSign() {
      this.$emit("goSign");
    }
  },
  destroyed() {}
};
</script>
<style lang="scss">
.tip {
  margin-top: 10px;
}
.create-account-wrap {
  .desc {
    margin-top: 6px;
  }
  .user-name-box {
    margin: 50px 0 0;
    .name-box {
      &.first {
        margin-right: 12px;
      }
      .name {
        width: 197px;
        height: 50px;
        box-sizing: border-box;
        margin-top: 12px;
      }
      .icon-box {
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        margin-right: 18px;
        z-index: 2;
      }
    }
  }
  .email-wrap {
    margin: 20px 0 0;
    .email-box {
      width: 406px;
      height: 50px;
      box-sizing: border-box;
      margin-top: 12px;
    }
    .icon-box {
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      margin-right: 18px;
      z-index: 2;
    }
  }
  .emailFormatErr {
    color: #fb7373;
    margin-top: 8px;
  }
  .next {
    width: 406px;
    height: 50px;
    margin-top: 20px;
  }
  .register-wrap {
    margin-top: 20px;
    .go-login {
      color: #4e748b;
      opacity: 0.8;
      text-decoration: underline;
      margin-left: 8px;
      &:hover {
        opacity: 1;
      }
    }
  }
  .divider {
    margin-top: 40px;
  }
  .login-other-wrap {
    margin-top: 20px;
    .login-inner-box {
      width: 197px;
      height: 50px;
      border-radius: 6px;
      border: 1px solid #eaeaea;
      transition: all 0.3s;
      &:hover {
        border-color: #87bcdc;
      }
      .google {
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }
      .facebook {
        width: 12px;
        height: 20px;
        margin-right: 20px;
      }
    }
  }
}
</style>
