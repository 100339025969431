<template>
  <div v-if="detail" class="detail-tab-inner  ">
    <div class="bad-rooms-wrap">
      <div v-for="(item, index) in currentDetail.roomInfoList" :key="index">
        <div class="bedroom-item fs16 fc666  active">
          <span v-if="item.key == 1">
            <span v-if="lang == 'CN'">单人间</span>
            <span v-else>Studio</span>
          </span>
          <span v-else>
            <span v-if="lang == 'CN'"> {{ item.key | toZhDigit }}人间 </span>
            <span v-else> {{ item.key }}BED</span>
          </span>
        </div>
        <div
          v-for="(room, i) in item.rooms"
          :key="i"
          class="bed-content dfr cross-center"
        >
          <!-- {{ item }} -->

          <div class="bed-img-wrap dfr flex-center pointer">
            <img
              class="bed-inner-img w100 h100 object-cover"
              :preview="room.images"
              :src="room.images[0]"
              alt=""
            />
            <div
              style="display:none"
              v-for="(img, _index) in room.images"
              :key="_index"
            >
              <img
                v-if="_index >= 1"
                :preview="room.images"
                :src="img"
                alt=""
                class="other "
              />
            </div>
          </div>
          <div class="bed-content-box">
            <div class="banner-type fs20 fw500 fc000">{{ room.unit }}</div>
            <div class="banner-price fs16 fc333 dfr cross-center ">
              <!-- status 1空 2有 -->
              <div v-if="room.status == 2" class="price-box ">
                <span v-if="room.floor_price > 0"
                  >${{ room.floor_price | formatPrice }}</span
                >
                <span v-if="room.top_price > 0 && room.floor_price > 0">-</span>
                <span v-if="room.top_price > 0"
                  >${{ room.top_price | formatPrice }}</span
                >
              </div>
              <div v-else class="price-box fc333 fs16 ">
                <span v-if="lang == 'CN'">已售罄</span>
                <span v-else>Sold out</span>
              </div>
              <div class="area-box dfr cross-center">
                <div>{{ room.area }}</div>
                <div class="area-unit por">
                  ft
                  <div class="m-unit">2</div>
                </div>
              </div>
            </div>
            <!-- <div style="width:100px">{{ item.imgs }}</div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-if="currentRooms.length" class="banner-wrap por ">
      <swiper
        ref="myProductSwiper"
        class="banner-swiper por w100 "
        :options="secondSwiperOption"
      >
        <swiper-slide
          class="banner-swiper-slide h100 w100"
          v-for="(item, index) in currentRooms"
          :key="index"
        >
          <div class="detail-banner-box">
            <div class="banner-img-box">
              <img
                :preview="item.image"
                class="w100 h100 object-cover pointer"
                :src="item.image"
                alt=""
              />
            </div>
            <div class="banner-type fs20 fw500 fc000">{{ item.unit }}</div>
            <div class="banner-price fs16 fc333 dfr cross-center ">
              <div v-if="item.status == 2" class="price-box ">
                <span v-if="item.floor_price > 0"
                  >${{ item.floor_price | formatPrice }}</span
                >
                <span v-if="item.top_price > 0 && item.floor_price > 0">-</span>
                <span v-if="item.top_price > 0"
                  >${{ item.top_price | formatPrice }}</span
                >
              </div>
              <div v-else class="price-box ">
                <span v-if="lang == 'CN'">已售罄</span>
                <span v-else>Sold out</span>
              </div>
              <div class="area-box dfr cross-center">
                <div>{{ item.area }}</div>
                <div class="area-unit por">
                  ft
                  <div class="m-unit">2</div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="  slide-btn left-btn poa pointer" @click="preSwiper">
        <i class="el-icon-back "></i>
      </div>
      <div class="  slide-btn right-btn poa pointer" @click="nextSwiper">
        <i class="el-icon-right "></i>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    detail: Object,
    lang: String
  },
  data() {
    return {
      currentDetail: null,
      bedrooms: [],
      secondSwiperOption: {
        // loop: true,
        slidesPerView: 3,
        allowTouchMove: false,
        spaceBetween: 25,
        breakpoints: {
          100: {
            //当屏幕宽度大于等于320
            slidesPerView: 1.2,
            spaceBetween: 10
          },
          768: {
            //当屏幕宽度大于等于768
            slidesPerView: 2.1,
            spaceBetween: 20
          },
          1000: {
            //当屏幕宽度大于等于1280
            slidesPerView: 3,
            spaceBetween: 30
          },
          1280: {
            //当屏幕宽度大于等于1280
            slidesPerView: 3,
            spaceBetween: 30
          }
        }
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // }
      },
      currentRooms: []
    };
  },
  created() {
    this.currentDetail = this.detail;
    let allImgList = [];
    for (let key in this.currentDetail.rooms) {
      const data = {
        key: key,
        imgs: [],
        info: this.currentDetail.rooms[key][0],
        rooms: this.currentDetail.rooms[key]
      };
      this.currentDetail.rooms[key].forEach(item => {
        data.imgs.push(item.image);
      });

      allImgList.push(data);
    }
    this.currentDetail.roomInfoList = allImgList;
  },
  computed: {
    swiper() {
      return this.$refs.myProductSwiper.$swiper;
    }
  },
  mounted() {
    // this.bedrooms =
    let bedrooms = this.detail.rooms;

    if (bedrooms) {
      let firstKey = "";
      for (let key in bedrooms) {
        if (!firstKey) {
          firstKey = key;
        }
        this.bedrooms.push({
          active: false,
          name: key
        });
      }

      this.bedrooms[0] && (this.bedrooms[0].active = true);
      this.currentRooms = this.detail.rooms[firstKey];
    }
  },
  methods: {
    preview() {},
    selectBedroom(index, name) {
      this.bedrooms.map(item => {
        item.active = false;
        return item;
      });
      this.bedrooms[index].active = true;

      this.currentRooms = this.detail.rooms[name];
    },
    preSwiper() {
      this.swiper.slidePrev();
    },
    nextSwiper() {
      this.swiper.slideNext();
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.bed-content {
  margin-top: 10px;
}
.bed-img-wrap {
  width: 220px;
  height: 180px;
  margin-right: 10px;
  .bed-inner-img {
    max-width: 100%;
    max-height: 100%;
  }
}
.banner-price {
  margin-top: 10px;
}
.banner-swiper-slide.min-slide {
  width: 250px;
}
.min-slide-wrap {
  overflow-x: auto;
}
.min-slide {
  margin-right: 20px;
}
.detail-tab-inner {
  .title {
    margin-top: 50px;
  }
  .bad-rooms-wrap {
    margin: 20px 0 40px;
  }
  .bedroom-item {
    display: inline-block;
    margin: 8px 8px 0 0;
    padding: 9px 14px 10px;
    background: #f8f8f8;
    transition: "background" 0.3s;
    &:hover {
      background: #87bcdc;
      color: #fff;
    }
    &.active {
      background: #87bcdc;
      color: #fff;
    }
  }
  .banner-wrap {
    width: 800px;
    max-width: 100%;
    margin: 18px 0 0;
    .detail-banner-box {
      width: 230px;
      height: 255px;
      .banner-img-box {
        width: 250px;
        height: 180px;
      }
      .banner-type {
        margin: 20px 0 10px;
      }
    }
  }
}
.area-box {
  margin-left: 20px;
  .area-unit {
    .m-unit {
      position: absolute;
      top: -4px;
      right: -4px;
      font-size: 12px;
      transform: scale(0.6);
    }
  }
}

.slide-btn {
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  color: rgba(255, 255, 255, 0.5);
  z-index: 2;
  box-sizing: border-box;
  font-size: 26px;
  &.left-btn {
    left: 10px;
  }
  &.right-btn {
    right: 10px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.8) !important;
    color: #fff;
  }
}
@media screen and (max-width: 767px) {
  .detail-tab-inner {
    .title {
      margin-top: 20px;
      font-size: 28px;
    }
  }
  .slide-btn {
    width: 36px;
    height: 36px;
    transition: all 0s;
    background: rgba(0, 0, 0, 0.7) !important;
    color: #fff;
    font-size: 20px;
  }
}
</style>
