import Vue from "vue";
import localStore from "store";
import API from "./api";
import lang from "../lang/lang.json";
const config = require("../../config");

import { go, goReplace, back, setTitle } from "./util";
// 添加config配置
Vue.prototype.$config = config;

// 路由跳转
Vue.prototype.$go = go;

// 路由跳转replace
Vue.prototype.$goReplace = goReplace;

// 路由返回
Vue.prototype.$back = back;

// 接口请求
Vue.prototype.$API = API;

// 本地存储
Vue.prototype.$localStore = localStore;

// 文档title
Vue.prototype.$setTitle = setTitle;

Vue.prototype.$bus = new Vue();

Vue.prototype.$EN = lang.EN;
Vue.prototype.$CN = lang.CN;
