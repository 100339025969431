<template>
  <div class="me-app-wrap ">
    <div class="title dfr cross-center fs16 fc000">{{ langText.title }}</div>
    <div class="app-info-wrap">
      <div class="tabs-wrap fs22">
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="All Status" name="all">
            <me-detail></me-detail>
          </el-tab-pane>
          <el-tab-pane lazy label="In Review" name="review">
            <me-detail status="1"></me-detail>
          </el-tab-pane>
          <el-tab-pane lazy label="Succeed" name="succeed">
            <me-detail status="2"></me-detail>
          </el-tab-pane>
          <el-tab-pane lazy label="Waiting Move" name="waiting">
            <me-detail status="3"></me-detail>
          </el-tab-pane>
          <el-tab-pane lazy label="Checked in" name="checked">
            <me-detail status="4"></me-detail>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      activeName: "all",
      detail: {},
      page: 1,
      pageSize: 10,
      // 0已提交,1审核中,2申请成功,3等待入住,4已入住, 5已取消
      status: "",
      langText: {
        title: "My Account"
      }
    };
  },
  created() {},
  mounted() {
    this.initLang();
    this.$bus.$on("changeLang", () => {
      this.initLang();
    });
  },
  methods: {
    initLang() {
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.me;
      } else if (lang == "CN") {
        this.langText = this.$CN.me;
      }
    },
    handleTabClick() {},
    firstNameBlur() {},
    lastNameBlur() {},
    addressFBlur() {},
    addressSBlur() {}
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.me-app-wrap {
  .title {
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    padding: 0 30px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 6px;
  }
  .app-info-wrap {
    background: #fff;
    box-sizing: border-box;
    padding: 30px;
  }
}
</style>
