<template>
  <div class="sendMessage dfr flex-center pointer">
    <img src="../assets/images/send-message.png" alt="" class="message " />
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogShow: true
    };
  },
  created() {},
  mounted() {},
  methods: {},
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.sendMessage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 10%;
  right: 10%;
  .message {
    width: 77%;
  }
}
</style>
