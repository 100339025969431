import Vue from "vue";
import Router from "vue-router";
const config = require("../../config");
// 首页
const home = resolve => {
  import("../views/home.vue").then(module => {
    resolve(module);
  });
};
// 公寓列表
const apartmentList = resolve => {
  import("../views/apartmentList.vue").then(module => {
    resolve(module);
  });
};

// detail
const detail = resolve => {
  import("../views/detail.vue").then(module => {
    resolve(module);
  });
};
// me
const me = resolve => {
  import("../views/me.vue").then(module => {
    resolve(module);
  });
};
// contactUs
const contactUs = resolve => {
  import("../views/contactUs.vue").then(module => {
    resolve(module);
  });
};
// order
const order = resolve => {
  import("../views/order.vue").then(module => {
    resolve(module);
  });
};
Vue.use(Router);

export default new Router({
  base: config.routerBase,
  routes: [
    {
      path: "/",
      redirect: "/home"
    },

    {
      path: "/home",
      name: "home",
      component: home
    },
    {
      path: "/contactUs",
      name: "contactUs",
      component: contactUs
    },

    {
      path: "/apartmentList",
      name: "apartmentList",
      component: apartmentList
    },

    {
      path: "/detail",
      name: "detail",
      component: detail
    },
    {
      path: "/me",
      name: "me",
      component: me
    },
    {
      path: "/order",
      name: "order",
      component: order
    }
  ]
});
// 解决router 3.0 bug
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
