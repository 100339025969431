import router from "../router/index";
import store from "store";
import Vue from "vue";

/**
 * 是否是微信浏览器
 */
const isWeiXinBrowser = () => {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
};
const isIOSUser = () => {
  return false;
};
/**
 * 跳转
 */
const go = (name, query, needNewTag = false) => {
  if (needNewTag) {
    const { href } = router.resolve({
      name,
      query
    });
    window.open(href, "_blank");
  } else {
    router.push({
      name,
      query
    });
  }
};

/**
 *跳转但是不记录
 */
const goReplace = (name, query = null) => {
  router.replace({
    name,
    query
  });
};

/**
 * 路由返回
 */
const back = () => {
  if (isIOSUser()) {
    var ws = window.plus.webview.currentWebview();
    window.plus.webview.close(ws, "pop-out", 200);
  } else {
    router.back();
  }
};

/**
 * 防抖
 */
const debounce = (func, wait, immediate) => {
  var timeout, result;

  var debounced = function() {
    var context = this;
    var args = arguments;

    if (timeout) clearTimeout(timeout);
    if (immediate) {
      // 如果已经执行过，不再执行
      var callNow = !timeout;
      timeout = setTimeout(function() {
        timeout = null;
      }, wait);
      if (callNow) result = func.apply(context, args);
    } else {
      timeout = setTimeout(function() {
        func.apply(context, args);
      }, wait);
    }
    return result;
  };

  debounced.cancel = function() {
    clearTimeout(timeout);
    timeout = null;
  };

  return debounced;
};

/**
 * 监听窗口变化
 */
const windowRize = cb => {
  window.addEventListener("resize", debounce(cb, 100), false);
};

/**
 * 阿拉伯数字转中文
 */
const toZhDigit = digit => {
  digit = typeof digit === "number" ? String(digit) : digit;
  const zh = ["零", "一", "两", "三", "四", "五", "六", "七", "八", "九"];
  const unit = ["千", "百", "十", ""];
  const quot = [
    "万",
    "亿",
    "兆",
    "京",
    "垓",
    "秭",
    "穰",
    "沟",
    "涧",
    "正",
    "载",
    "极",
    "恒河沙",
    "阿僧祗",
    "那由他",
    "不可思议",
    "无量",
    "大数"
  ];

  let breakLen = Math.ceil(digit.length / 4);
  let notBreakSegment = digit.length % 4 || 4;
  let segment;
  let zeroFlag = [],
    allZeroFlag = [];
  let result = "";

  while (breakLen > 0) {
    if (!result) {
      // 第一次执行
      segment = digit.slice(0, notBreakSegment);
      let segmentLen = segment.length;
      for (let i = 0; i < segmentLen; i++) {
        if (segment[i] != 0) {
          if (zeroFlag.length > 0) {
            result += "零" + zh[segment[i]] + unit[4 - segmentLen + i];
            // 判断是否需要加上 quot 单位
            if (i === segmentLen - 1 && breakLen > 1) {
              result += quot[breakLen - 2];
            }
            zeroFlag.length = 0;
          } else {
            result += zh[segment[i]] + unit[4 - segmentLen + i];
            if (i === segmentLen - 1 && breakLen > 1) {
              result += quot[breakLen - 2];
            }
          }
        } else {
          // 处理为 0 的情形
          if (segmentLen == 1) {
            result += zh[segment[i]];
            break;
          }
          zeroFlag.push(segment[i]);
          continue;
        }
      }
    } else {
      segment = digit.slice(notBreakSegment, notBreakSegment + 4);
      notBreakSegment += 4;

      for (let j = 0; j < segment.length; j++) {
        if (segment[j] != 0) {
          if (zeroFlag.length > 0) {
            // 第一次执行zeroFlag长度不为0，说明上一个分区最后有0待处理
            if (j === 0) {
              result += quot[breakLen - 1] + zh[segment[j]] + unit[j];
            } else {
              result += "零" + zh[segment[j]] + unit[j];
            }
            zeroFlag.length = 0;
          } else {
            result += zh[segment[j]] + unit[j];
          }
          // 判断是否需要加上 quot 单位
          if (j === segment.length - 1 && breakLen > 1) {
            result += quot[breakLen - 2];
          }
        } else {
          // 第一次执行如果zeroFlag长度不为0, 且上一划分不全为0
          if (j === 0 && zeroFlag.length > 0 && allZeroFlag.length === 0) {
            result += quot[breakLen - 1];
            zeroFlag.length = 0;
            zeroFlag.push(segment[j]);
          } else if (allZeroFlag.length > 0) {
            // 执行到最后
            if (breakLen == 1) {
              result += "";
            } else {
              zeroFlag.length = 0;
            }
          } else {
            zeroFlag.push(segment[j]);
          }

          if (
            j === segment.length - 1 &&
            zeroFlag.length === 4 &&
            breakLen !== 1
          ) {
            // 如果执行到末尾
            if (breakLen === 1) {
              allZeroFlag.length = 0;
              zeroFlag.length = 0;
              result += quot[breakLen - 1];
            } else {
              allZeroFlag.push(segment[j]);
            }
          }
          continue;
        }
      }

      --breakLen;
    }

    return result;
  }
};

/**
 * title
 */
const setTitle = title => {
  document.title = title;
};

/**
 * 获取url参数
 */
const getParam = name => {
  var reg = new RegExp("([?])" + name + "=([^&]*)(&|$)", "i");
  var r = decodeURIComponent(window.location.hash)
    .substr(1)
    .match(reg);
  if (r != null) return r[2];

  reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  r = decodeURIComponent(window.location.search)
    .substr(1)
    .match(reg);
  if (r != null) return r[2];

  return null;
};

/**
 * 登出
 */
const logOut = () => {
  store.set("isLogin", false);
  store.remove("JWT");
  store.remove("userId", "");
  // TODO
  Vue.prototype.$bus.$emit("logout");
  // go("login");
};

/**
 * scroll
 */
/**
 @description 页面垂直平滑滚动到指定滚动高度
 @author zhangxinxu(.com)
*/
const scrollSmoothTo = function(position) {
  // if (!window.requestAnimationFrame) {
  //   window.requestAnimationFrame = function(callback) {
  //     return setTimeout(callback, 17);
  //   };
  // }
  // 当前滚动高度
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  // 滚动step方法
  let step = function() {
    // 距离目标滚动距离
    let distance = position - scrollTop;
    // 目标滚动位置
    scrollTop = scrollTop + distance / 5;
    if (Math.abs(distance) < 1) {
      window.scrollTo(0, position);
    } else {
      window.scrollTo(0, scrollTop);
      // requestAnimationFrame(step);
    }
  };
  step();
};

const getPosition = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          let data = {
            latitude: latitude,
            longitude: longitude
          };
          resolve(data);
        },
        function() {
          reject(arguments);
        }
      );
    } else {
      reject("你的浏览器不支持当前地理位置信息获取");
    }
  });
};

export {
  go,
  goReplace,
  back,
  windowRize,
  setTitle,
  getParam,
  logOut,
  isWeiXinBrowser,
  debounce,
  toZhDigit,
  scrollSmoothTo,
  getPosition
};
