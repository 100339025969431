<template>
  <div id="app" class="fs20 fc333 ">
    <app-login
      :show.sync="showLogin"
      :type="type"
      @typeChange="typeChange"
    ></app-login>
    <app-agreement :show.sync="innerVisible"></app-agreement>
    <app-header :activeNum="activeNum"></app-header>
    <!-- TODO -->

    <router-view class="max-wrap-content" :key="$route.fullPath" />

    <app-footer></app-footer>
    <!-- <send-message @click.native="showDialogHandle"></send-message>
    <messageDialog
      :show="dialogShow"
      @close="dialogShow = false"
    ></messageDialog> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogShow: false,
      showLogin: false,
      type: "",
      activeNum: "",
      innerVisible: false
    };
  },
  created() {
    let lang = this.$localStore.get("lang");
    if (!lang) {
      this.$localStore.set("lang", "EN");
    }
  },
  mounted() {
    this.initActiveNum(this.$route.name);
    this.$bus.$on("showLogin", type => {
      this.type = type;
      this.showLogin = true;
    });
    this.$bus.$on("showAgree", () => {
      this.innerVisible = true;
    });
  },
  watch: {
    $route(route) {
      this.initActiveNum(route.name);
    }
  },
  methods: {
    showDialogHandle() {
      this.dialogShow = true;
    },
    initActiveNum(routerName) {
      const routerNames = ["apartmentList", "", "", "", "", "contactUs"];
      if (routerNames.indexOf(routerName) != -1) {
        this.activeNum = routerNames.indexOf(routerName) + "";
      } else {
        this.activeNum = "";
      }
    },
    typeChange() {
      this.type = "";
    }
  },
  destroyed() {}
};
</script>
<style lang="scss">
.max-wrap-content {
  min-height: calc(100vh - 80px - 446px);
}
</style>
