<template>
  <!-- @keyup.enter.native="" -->
  <div class="me-account-wrap w100">
    <div class="title dfr cross-center fs16 fc000">{{ langText.account }}</div>
    <div class="account-info-wrap">
      <!-- name -->
      <div class="inner-title-wrap dfr    cross-center">
        <div class="line"></div>
        <div class="inner-title fs18 fc000 fb">
          {{ langText.changePassword }}
        </div>
      </div>
      <div class="info-input-max-wrap dfr  mdfc  cross-center ">
        <div class="left-input-box">
          <div class="fs14 fc666">
            {{ langText.password.old }}
          </div>
          <div class="cell-input-box">
            <el-input
              v-model.trim="old"
              clearable
              type="password"
              @keyup.enter.native="confirmChange"
              :placeholder="langText.password.old"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="info-input-max-wrap dfr  mdfc  cross-center ">
        <div class="left-input-box">
          <div class="fs14 fc666">
            {{ langText.password.password }}
          </div>
          <div class="cell-input-box">
            <el-input
              v-model.trim="password"
              clearable
              type="password"
              show-password
              @keyup.enter.native="confirmChange"
              :placeholder="langText.password.password"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="info-input-max-wrap dfr  mdfc  cross-center ">
        <div class="left-input-box">
          <div class="fs14 fc666">
            {{ langText.password.confirmPassword }}
          </div>
          <div class="cell-input-box">
            <el-input
              v-model.trim="confirm"
              clearable
              type="password"
              show-password
              @keyup.enter.native="confirmChange"
              :placeholder="langText.password.confirmPassword"
            ></el-input>
          </div>
        </div>
      </div>
      <div @click="confirmChange" class="btn  pointer fcfff dfr flex-center">
        {{ langText.password.confirm }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      old: "",
      password: "",
      confirm: "",
      langText: {
        password: {
          password: "New Password",
          old: "Old Password",
          confirm: "Confirm"
        }
      },
      lang: ""
    };
  },
  created() {},
  mounted() {
    this.initLang();
    this.$bus.$on("changeLang", () => {
      this.initLang();
    });
  },
  methods: {
    initLang() {
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.me;
      } else if (lang == "CN") {
        this.langText = this.$CN.me;
      }
      this.lang = lang;
    },
    changePassword() {},
    changeOld() {},
    async confirmChange() {
      let passwordText = "Please enter a new password";
      let oldText = "Please enter the old password";
      let successText = "Modified successfully";
      let passwordOldDiff = "The passwords are not the same twice";
      let passwordLength = "Must contain at least 8 characters";
      if (this.lang == "CN") {
        passwordText = "请输入新密码";
        oldText = "请输入旧密码";
        successText = "修改成功";
        passwordOldDiff = "两次密码不一致";
        passwordLength = "长度小于8";
      }
      if (!this.old) {
        this.$message(oldText);
        return;
      }

      if (!this.password) {
        this.$message(passwordText);
        return;
      }
      if (this.password.length < 8) {
        this.$message(passwordLength);
        return;
      }

      if (!this.confirm) {
        this.$message(passwordText);
        return;
      }
      if (this.confirm != this.password) {
        this.$message(passwordOldDiff);
        return;
      }
      const data = {
        old_password: this.old,
        new_password: this.password,
        confirm: this.confirm
      };
      const resp = await this.$API
        .post("changePassword", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.old = "";
        this.password = "";
        this.confirm = "";
        this.$message({
          message: successText,
          type: "success"
        });
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.me-account-wrap {
  .title {
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    padding: 0 30px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 6px;
  }
  .account-info-wrap {
    padding: 30px;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    .info-input-max-wrap {
      margin-top: 30px;
      .right-input-box {
        .first {
          margin-right: 30px;
        }
        .right-inner-box {
          width: 165px;
          height: 40px;
          margin-top: 8px;
          border-radius: 6px;
        }
      }
    }
    .left-input-box {
      margin-right: 30px;
    }
    .cell-input-box {
      width: 360px;
      height: 42px;
      margin-top: 8px;
    }
    .inner-title-wrap {
      &.margin {
        margin-top: 50px;
      }
      .line {
        width: 3px;
        height: 16px;
        background: #87bcdc;
        margin-right: 12px;
      }
    }
  }
}
.btn {
  width: 100px;
  height: 40px;
  background: #87bcdc;
  border-radius: 4px;
  font-size: 16px;
  margin: 20px 0 0;
}
@media screen and (max-width: 767px) {
  .me-account-wrap {
    .title {
      padding: 30px 20px;
    }
    .account-info-wrap {
      padding: 30px 20px;
    }
    .info-input-max-wrap {
      width: 100%;
    }
    .left-input-box {
      width: 100%;
      margin-right: 0 !important;
      margin-bottom: 20px;
      .cell-input-box {
        width: 100%;
      }
    }
    .right-input-box {
      width: 100%;
      flex-direction: column;
      .cell-input-box {
        width: 100%;
      }
      .first,
      .second {
        margin-right: 0 !important;
        .right-inner-box {
          width: 100% !important;
          .filter-item {
            width: 100%;
          }
        }
      }
      .second {
        margin-top: 20px;
      }
    }
  }
}
</style>
