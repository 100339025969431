<template>
  <div
    v-if="detailInfo"
    @click="goDetail(detailInfo.id)"
    class="poular-item pointer "
    :class="[unShowMap ? 'hide-map' : 'show-map']"
  >
    <div class="img-banner-box por">
      <swiper
        ref="myProductSwiper"
        class="banner-swiper por w100 "
        :options="secondSwiperOption"
      >
        <swiper-slide
          class="banner-swiper-slide h100 w100"
          v-for="(item, index) in detailInfo.images"
          :key="index"
        >
          <!-- <div class="product-img-box"> -->
          <img
            :src="`${item}?x-oss-process=style/home_detail`"
            class="w100 h100 object-cover"
            alt=""
          />
          <!-- </div> -->

          <!-- <div class="product-swiper-pagination" slot="pagination"></div> -->
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="  slide-btn left-btn poa pointer" @click.stop="preSwiper">
        <i class="el-icon-back "></i>
      </div>
      <div class="  slide-btn right-btn poa pointer" @click.stop="nextSwiper">
        <i class="el-icon-right "></i>
      </div>
    </div>
    <div class="popular-content text-center">
      <div class="popular-title fs20 fc333 fb">{{ detailInfo.name }}</div>
      <div v-if="detailInfo.describe" class="popular-recommend fs14 fc333">
        {{ detailInfo.describe }}
      </div>
      <div class="tag-wrap dfr ">
        <div
          class="data-tag"
          v-for="(item, index) in detailInfo.tags"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="address-info dfr cross-start fs14 fc666">
        <div class="address-icon">
          <img src="../assets/images/address.png" class="w100 " alt="" />
        </div>
        <div class="address">
          <span>{{ detailInfo.address }}</span>
          <!-- <span v-if="detailInfo.street">,</span>
          <span v-if="detailInfo.street">{{ detailInfo.street }}</span> -->
        </div>
      </div>
      <div class="rooms-box dfr cross-center main-between">
        <div class="room-f dfc cross-center main-between">
          <div class="room-top dfr cross-cneter ">
            <div class="price-box fs16 fc333">
              ${{ detailInfo.floor_price | formatPrice }} - ${{
                detailInfo.top_price | formatPrice
              }}
            </div>
          </div>
          <div class="fs14  fc333 fw500 room-desc">
            <span v-if="lang == 'CN'">价格</span>
            <span v-else>Price</span>
          </div>
        </div>
        <div class="room-s dfc cross-center main-between">
          <div class="room-top dfr cross-cneter">
            <div class="room-icon">
              <img src="../assets/images/bedroom.png" class="w100" alt="" />
            </div>

            <div class="fs16 fc333 fw500">{{ detailInfo.bedrooms }}</div>
          </div>
          <div class="fs14 fc333 fw500 room-desc">
            <span v-if="lang == 'CN'">卧室</span>
            <span v-else>Bedrooms</span>
          </div>
        </div>
        <div class="room-t dfc cross-center main-between">
          <div class="room-top dfr cross-cneter">
            <div class="room-icon">
              <img src="../assets/images/bath.png" class="w100" alt="" />
            </div>

            <div class="fs16 fc333 fw500">{{ detailInfo.bath }}</div>
          </div>
          <div class="fs14 fc333 fw500 room-desc">
            <span v-if="lang == 'CN'">浴室</span>
            <span v-else>Bath</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    unShowMap: {
      type: Boolean,
      default: true
    },
    info: Object,
    lang: String
  },
  data() {
    return {
      detailInfo: null,
      secondSwiperOption: {
        loop: true
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // }
      }
    };
  },
  created() {
    this.initPage();
  },
  computed: {
    swiper() {
      return this.$refs.myProductSwiper.$swiper;
    }
  },
  mounted() {
    let info = this.info;
    if (info.max_rooms == info.min_rooms) {
      info.rooms = parseFloat(info.max_rooms);
    } else {
      info.rooms = `${parseFloat(info.min_rooms)} - ${parseFloat(
        info.max_rooms
      )}`;
    }
    if (info.max_bedrooms == info.min_bedrooms) {
      info.bedrooms = parseFloat(info.max_bedrooms);
    } else {
      info.bedrooms = `${parseFloat(info.min_bedrooms)} - ${parseFloat(
        info.max_bedrooms
      )}`;
    }
    if (info.max_bath == info.min_bath) {
      info.bath = parseFloat(info.max_bath);
    } else {
      info.bath = `${parseFloat(info.min_bath)} - ${parseFloat(info.max_bath)}`;
    }
    this.detailInfo = info;
  },
  methods: {
    async initPage() {},
    goDetail(id) {
      if (id) {
        this.$go("detail", { id });
      }
    },
    preSwiper() {
      this.swiper.slidePrev();
    },
    nextSwiper() {
      this.swiper.slideNext();
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.img-banner-box {
  width: 100%;
  height: 260px;

  &:hover {
    .slide-btn {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }

  .banner-swiper-slide {
    width: 100%;
    height: 260px;
    overflow: hidden;
    border-radius: 6px 6px 0 0;
  }
}
.room-top .price-box {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.show-map-wrap {
  .tag-wrap {
    max-height: 50px;

    overflow-y: auto;
    /* 及背景 高宽分别对应横竖滚动条的尺寸 */
    &::-webkit-scrollbar {
      width: 4px;
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: #87bcdc;
    }
  }
}
.tag-wrap {
  width: 84%;
  margin: 10px auto 0;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0;
  align-items: center;
  .data-tag {
    padding: 2px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background: #f4f4f4;
    color: #484848;
    font-size: 14px;
    margin: 0 10px 10px 0;
  }
}
.slide-btn {
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.5s;
  color: rgba(255, 255, 255, 0);
  z-index: 2;
  box-sizing: border-box;
  font-size: 20px;
  &.left-btn {
    left: 10px;
  }
  &.right-btn {
    right: 10px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.7) !important;
    color: #fff;
  }
}
.poular-item {
  width: 380px;
  min-height: 520px;
  background: #ffffff;
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  margin: 30px 0 0 0;
  box-sizing: border-box;
  // transition: all 0.3s;
  // transform-origin: center;
  &.hide-map {
    display: flex;
    flex-direction: column;
  }
  &.show-map {
    display: flex;
    flex-direction: row;
    width: 600px;
    max-width: 100%;
    min-height: 188px;
    .tag-wrap {
      width: 100%;
    }
    .img-banner-box {
      width: 275px;
      // height: 100%;
      height: 230px;
      border-radius: 6px;
      overflow: hidden;
    }
    .popular-content {
      padding: 20px 20px 0;
      width: 325px;
      height: 230px;
      box-sizing: border-box;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .popular-recommend {
        margin: 0 0 10px;
      }
      .address-info {
        width: 285px;
        box-sizing: border-box;
        margin: 14px 0 0 0;
      }
      .rooms-box {
        width: 100%;
        box-sizing: border-box;
        margin: 10px 0 0 0;
        padding-top: 0;
        border-top: 1px solid transparent;
        .room-top {
          margin-bottom: 10px;
        }
        .room-desc {
          opacity: 0;
        }
      }
    }
  }
  .img-box {
    width: 380px;
    height: 260px;
  }

  .popular-content {
    padding: 20px 0 30px;
    // transition: all 0.3s;
    .popular-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .popular-recommend {
      width: 84%;
      margin: 10px auto 0;
      padding-top: 6px;
      box-sizing: border-box;
      min-height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .price-box {
      height: 17px;
    }
    .address-info {
      width: 84%;
      margin: 14px auto 0;
      // transition: all 0.3s;
      .address-icon {
        width: 12px;
        height: 12px;
        margin-right: 6px;
      }
      .address {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }
    .rooms-box {
      width: 84%;
      margin: 30px auto 0;
      padding-top: 30px;
      border-top: 1px solid #eee;
      // transition: all 0.3s;
      .room-top {
        margin-bottom: 14px;
        // transition: all 0.3s;
      }
      .room-icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .img-banner-box .slide-btn {
    background: rgba(0, 0, 0, 0.5) !important;
    color: #fff !important;
  }
}
</style>
