<template>
  <!-- @keyup.enter.native="" -->
  <div class="me-account-wrap w100">
    <div class="title dfr cross-center fs16 fc000">{{ langText.title }}</div>
    <div class="account-info-wrap">
      <!-- name -->
      <div class="inner-title-wrap dfr    cross-center">
        <div class="line"></div>
        <div class="inner-title fs18 fc000 fb">
          {{ langText.personalInformation.topic }}
        </div>
      </div>
      <div class="info-input-max-wrap dfr  mdfc  cross-center ">
        <div class="left-input-box">
          <div class="fs14 fc666">
            {{ langText.personalInformation.firstname }}
          </div>
          <div class="cell-input-box">
            <el-input
              v-model.trim="firstName"
              @blur="changeFirstName"
              clearable
              :placeholder="langText.personalInformation.firstname"
            ></el-input>
          </div>
        </div>
        <div class="right-input-box">
          <div class="fs14 fc666">
            {{ langText.personalInformation.lastname }}
          </div>
          <div class="cell-input-box">
            <el-input
              v-model.trim="lastName"
              @blur="changeLastName"
              clearable
              :placeholder="langText.personalInformation.lastname"
            ></el-input>
          </div>
        </div>
      </div>
      <!-- address -->
      <div class="inner-title-wrap margin   dfr  cross-center">
        <div class="line"></div>
        <div class="inner-title fs18 fc000 fb">
          {{ langText.address.topic }}
        </div>
      </div>
      <!-- address info -->
      <div class="info-input-max-wrap dfr  mdfc  cross-center ">
        <div class="left-input-box">
          <div class="fs14 fc666">{{ langText.address.line1 }}</div>
          <div class="cell-input-box">
            <el-input
              v-model.trim="addressF"
              @blur="addressFChange"
              clearable
              :placeholder="langText.address.line1"
            ></el-input>
          </div>
        </div>
        <div class="right-input-box">
          <div class="fs14 fc666">{{ langText.address.line2 }}</div>
          <div class="cell-input-box">
            <el-input
              v-model.trim="addressS"
              @blur="addressSChange"
              clearable
              :placeholder="langText.address.line2"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="info-input-max-wrap dfr  mdfc  cross-center ">
        <div class="left-input-box">
          <div class="fs14 fc666">{{ langText.address.city }}</div>
          <div class="cell-input-box">
            <el-select
              class="filter-item w100"
              v-model="city"
              filterable
              :placeholder="langText.address.ciity"
              allow-create
              clearable
              @change="cityChange"
            >
              <el-option
                v-for="item in citys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right-input-box dfr ">
          <div class="first">
            <div class="fs14 fc666">{{ langText.address.state }}</div>
            <div class="right-inner-box">
              <el-select
                class="filter-item"
                v-model="currentState"
                filterable
                :placeholder="langText.address.state"
                allow-create
                clearable
                @change="initState"
              >
                <el-option
                  v-for="item in states"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="second">
            <div class="fs14 fc666">{{ langText.address.zipCode }}</div>
            <div class="right-inner-box">
              <el-input
                v-model.trim="zipCode"
                @blur="zipCodeChange"
                clearable
                :placeholder="langText.address.zipCode"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <!-- Phone -->
      <div class="inner-title-wrap margin dfr    cross-center">
        <div class="line"></div>
        <div class="inner-title fs18 fc000 fb">{{ langText.phone.topic }}</div>
      </div>
      <!-- phone info -->
      <div class="info-input-max-wrap dfr  mdfc  cross-center ">
        <div class="left-input-box">
          <div class="fs14 fc666">{{ langText.phone.phoneTitle }}</div>
          <div class="cell-input-box">
            <el-input
              v-model.trim="phone"
              @blur="telChange"
              clearable
              :placeholder="langText.phone.phoneTitle"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainCity from "../../utils/mainCity";
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      city: "",
      zipCode: "",
      phone: "",
      addressF: "",
      addressS: "",
      states: [],
      citys: [],
      currentState: "",
      userInfo: null,
      langText: {
        title: "My Account",
        personalInformation: {
          topic: "Personal Information",
          firstname: "First Name",
          lastname: "Last Name"
        },
        address: {
          topic: "address",
          line1: "Address Line1",
          line2: "Address Line2",
          city: "City",
          state: "State",
          zipCode: "Zip Code"
        },
        phone: {
          topic: "Phone",
          phoneTitle: "Mobile Phone"
        }
      }
    };
  },
  created() {
    this.initData();
    this.initInfo();
  },
  mounted() {
    this.initLang();
    this.$bus.$on("changeLang", () => {
      this.initLang();
    });
  },
  methods: {
    initLang() {
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.me;
      } else if (lang == "CN") {
        this.langText = this.$CN.me;
      }
    },
    initData() {
      mainCity.states.forEach(item => {
        this.states.push({
          value: item,
          label: item
        });
      });
      mainCity.city.forEach(item => {
        this.citys.push({
          value: item,
          label: item
        });
      });
    },
    async initInfo() {
      const resp = await this.$API.post("userInfo").then(resp => resp);

      if (resp.code == 1000) {
        const userInfo = resp.data.info;
        const addressInfo = resp.data.address;
        this.userInfo = resp.data.info;
        this.firstName = userInfo.first_name;
        this.lastName = userInfo.last_name;

        this.phone = userInfo.tel || "";
        if (addressInfo) {
          this.addressF = addressInfo.address || "";
          this.addressS = addressInfo.detail || "";
          this.currentState = addressInfo.state || "";
          this.city = addressInfo.city || "";
          this.zipCode = addressInfo.code || "";
        }
      }
    },
    async changeFirstName() {
      this.$bus.$emit("updateInfo");
      this.updateInfo();
    },
    async changeLastName() {
      this.$bus.$emit("updateInfo");
      this.updateInfo();
    },
    addressFChange() {
      this.updateInfo();
    },
    addressSChange() {
      this.updateInfo();
    },
    zipCodeChange() {
      this.updateInfo();
    },
    cityChange() {
      this.updateInfo();
    },
    telChange() {
      this.updateInfo();
    },
    initState() {
      this.updateInfo();
    },
    async updateInfo() {
      const data = {
        first_name: this.firstName,
        last_name: this.lastName,
        tel: this.phone,
        address: this.addressF,
        detail: this.addressS,
        city: this.city,
        state: this.currentState,
        code: this.zipCode
      };

      await this.$API.post("updateInfo", data).then(resp => resp);
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.me-account-wrap {
  .title {
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    padding: 0 30px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 6px;
  }
  .account-info-wrap {
    padding: 30px;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    .info-input-max-wrap {
      margin-top: 30px;
      .right-input-box {
        .first {
          margin-right: 30px;
        }
        .right-inner-box {
          width: 165px;
          height: 40px;
          margin-top: 8px;
          border-radius: 6px;
        }
      }
    }
    .left-input-box {
      margin-right: 30px;
    }
    .cell-input-box {
      width: 360px;
      height: 42px;
      margin-top: 8px;
    }
    .inner-title-wrap {
      &.margin {
        margin-top: 50px;
      }
      .line {
        width: 3px;
        height: 16px;
        background: #87bcdc;
        margin-right: 12px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .me-account-wrap {
    .title {
      padding: 30px 20px;
    }
    .account-info-wrap {
      padding: 30px 20px;
    }
    .info-input-max-wrap {
      width: 100%;
    }
    .left-input-box {
      width: 100%;
      margin-right: 0 !important;
      margin-bottom: 20px;
      .cell-input-box {
        width: 100%;
      }
    }
    .right-input-box {
      width: 100%;
      flex-direction: column;
      .cell-input-box {
        width: 100%;
      }
      .first,
      .second {
        margin-right: 0 !important;
        .right-inner-box {
          width: 100% !important;
          .filter-item {
            width: 100%;
          }
        }
      }
      .second {
        margin-top: 20px;
      }
    }
  }
}
</style>
