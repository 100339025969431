var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-progress dfr"},[_c('div',{staticClass:"left dfc flex-center"},[_c('div',{staticClass:"no fs14 fc999 "},[_vm._v("NO. "+_vm._s(_vm.detail.order_no))]),_c('div',{staticClass:"active fs24 fb "},[_vm._v(" "+_vm._s(_vm.statusMap[_vm.detail.status])+" ")]),(_vm.detail.status == 1)?_c('div',{staticClass:"no-btn pointer fcfff fs14",on:{"click":function($event){return _vm.cancelOrder(_vm.detail.id)}}},[_vm._v(" Withdraw ")]):_vm._e(),(_vm.detail.status == 2)?_c('div',{staticClass:"no-btn pointer fcfff fs14"},[_vm._v(" Advance deposit ")]):_vm._e()]),(_vm.detail.status == 5)?_c('div',{staticClass:"right dfr cross-center"},[_vm._m(0),_c('div',{staticClass:"line"}),_vm._m(1),_c('div',{staticClass:"line"}),_vm._m(2),_c('div',{staticClass:"line"}),_vm._m(3),_c('div',{staticClass:"line"}),_vm._m(4)]):_c('div',{staticClass:"right dfr cross-center"},[(_vm.detail.status == 2)?_c('div',{staticClass:"success-desc dfr cross-center fs14 fc000"},[_vm._m(5),_vm._v(" After the deposit payment is successful, you can wait to move in ")]):_vm._e(),_c('div',{staticClass:"step-1 step",class:[
        _vm.detail.status == 0 ? 'active' : '',
        _vm.detail.status >= 1 ? 'success' : ''
      ]},[_c('div',{staticClass:"step-default"},[_c('div',{staticClass:"step-number"},[_vm._v("1")]),(_vm.detail.status >= 1)?_c('img',{staticClass:"success-img",attrs:{"src":require("../../assets/images/order-success.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"desc-f"},[_vm._v("Submitted")]),_c('div',{staticClass:"desc-s"},[_vm._v("2021-02-24 08:05:21")])]),_c('div',{staticClass:"line",class:[_vm.detail.status >= 1 ? 'active' : '']}),_c('div',{staticClass:"step-2 step",class:[
        _vm.detail.status == 1 ? 'active' : '',
        _vm.detail.status >= 2 ? 'success' : ''
      ]},[_c('div',{staticClass:"step-default"},[_c('div',{staticClass:"step-number"},[_vm._v("2")]),(_vm.detail.status >= 2)?_c('img',{staticClass:"success-img",attrs:{"src":require("../../assets/images/order-success.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"desc-f"},[_vm._v("In Review")]),_c('div',{staticClass:"desc-s"})]),_c('div',{staticClass:"line",class:[_vm.detail.status >= 2 ? 'active' : '']}),_c('div',{staticClass:"step-3 step",class:[
        _vm.detail.status == 2 ? 'active' : '',
        _vm.detail.status >= 3 ? 'success' : ''
      ]},[_c('div',{staticClass:"step-default"},[_c('div',{staticClass:"step-number"},[_vm._v("3")]),(_vm.detail.status >= 3)?_c('img',{staticClass:"success-img",attrs:{"src":require("../../assets/images/order-success.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"desc-f"},[_vm._v("Succeed")]),_c('div',{staticClass:"desc-s"})]),_c('div',{staticClass:"line",class:[_vm.detail.status >= 3 ? 'active' : '']}),_c('div',{staticClass:"step-4 step",class:[
        _vm.detail.status == 3 ? 'active' : '',
        _vm.detail.status >= 4 ? 'success' : ''
      ]},[_c('div',{staticClass:"step-default"},[_c('div',{staticClass:"step-number"},[_vm._v("4")]),(_vm.detail.status >= 4)?_c('img',{staticClass:"success-img",attrs:{"src":require("../../assets/images/order-success.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"desc-f"},[_vm._v("Waiting Move")]),_c('div',{staticClass:"desc-s"})]),_c('div',{staticClass:"line",class:[_vm.detail.status >= 4 ? 'active' : '']}),_c('div',{staticClass:"step-5 step",class:[
        _vm.detail.status == 4 ? 'active' : '',
        _vm.detail.status >= 5 ? 'success' : ''
      ]},[_c('div',{staticClass:"step-default"},[_c('div',{staticClass:"step-number"},[_vm._v("5")]),(_vm.detail.status >= 5)?_c('img',{staticClass:"success-img",attrs:{"src":require("../../assets/images/order-success.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"desc-f"},[_vm._v("Checked in")]),_c('div',{staticClass:"desc-s"})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-1 step"},[_c('div',{staticClass:"step-default"},[_c('div',{staticClass:"step-number"},[_vm._v("1")])]),_c('div',{staticClass:"desc-f"},[_vm._v("Submitted")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-2 step"},[_c('div',{staticClass:"step-default"},[_c('div',{staticClass:"step-number"},[_vm._v("2")])]),_c('div',{staticClass:"desc-f"},[_vm._v("In Review")]),_c('div',{staticClass:"desc-s"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-3 step"},[_c('div',{staticClass:"step-default"},[_c('div',{staticClass:"step-number"},[_vm._v("3")])]),_c('div',{staticClass:"desc-f"},[_vm._v("Succeed")]),_c('div',{staticClass:"desc-s"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-4 step"},[_c('div',{staticClass:"step-default"},[_c('div',{staticClass:"step-number"},[_vm._v("4")])]),_c('div',{staticClass:"desc-f"},[_vm._v("Waiting Move")]),_c('div',{staticClass:"desc-s"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-5 step"},[_c('div',{staticClass:"step-default"},[_c('div',{staticClass:"step-number"},[_vm._v("5")])]),_c('div',{staticClass:"desc-f"},[_vm._v("Checked in")]),_c('div',{staticClass:"desc-s"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arrow-right "},[_c('img',{staticClass:"w100",attrs:{"src":require("../../assets/images/arrow-right.png"),"alt":""}})])}]

export { render, staticRenderFns }