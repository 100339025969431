var isBuildTest = process.argv[process.argv.length - 1];
var routerBase = "/";
if (isBuildTest == "--test") {
  routerBase = "/test";
}

module.exports = {
  domain: "http://admin.allsethomeus.com",
  apiUrl: "http://admin.allsethomeus.com/api",
  // domain: "http://luoju.shifang.tech",
  // apiUrl: "http://luoju.shifang.tech/api",
  strictMode: false,
  routerBase: routerBase,
  mapKey: "AIzaSyDkjg3cG7o4K5OKWysF6ASAnh2TzHIqoKw"
};
