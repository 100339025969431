<template>
  <div class="footer  ">
    <div class="footer-content dfr mdfc">
      <div class="footer-left">
        <div class="logo-wrap">
          <img class="w100" :src="leftInfo.icon" alt="" />
        </div>
        <div class="logo-desc fs14 op5 fcfff">
          {{ leftInfo.desc }}
        </div>
        <div class="share-icon-box dfr">
          <div
            v-for="(item, index) in shareIcon"
            :key="index"
            class="share-icon pointer dfr flex-center por"
          >
            <div class="qrcode-wrrap poa ">
              <img :src="item.qrcode" alt="" class="qrcode w100 h100" />
            </div>
            <img class="contact-icon-us " :src="item.icon" alt="" />
          </div>
        </div>
      </div>
      <div class="footer-right">
        <div class="title fs30  fcfff fb">{{ langText.contactus }}</div>
        <div class="contact-us-box fcfff dfc op8 mdfc">
          <div v-for="(item, index) in infoList" :key="index" class="my-info">
            <div
              v-if="item.type == 'tel'"
              class="content  dfr por"
              style="display:none;"
            >
              <div class="tel-icon icon-box">
                <img src="../assets/images/tel.png" alt="" class="tel w100 " />
              </div>
              <div class="dfc">
                <a class="can-hover fs18 fcfff op7" :href="`tel:${item.title}`">
                  {{ item.title }}</a
                >
                <div class="desc op5 fs12  ">{{ langText.telDesc }}</div>
              </div>
            </div>
            <div
              v-else-if="item.type == 'email'"
              class="content  email-link dfr"
            >
              <div class="email-icon icon-box">
                <img src="../assets/images/email.png" alt="" class="tel w100" />
              </div>
              <a
                class="fs18 can-hover fcfff op7"
                :href="`mailto:${item.title}`"
                >{{ item.title }}</a
              >
            </div>
            <div v-else class="content dfr">
              <div class="address-icon icon-box">
                <img
                  src="../assets/images/wechat.png"
                  alt=""
                  class="tel w100"
                />
              </div>
              <div class="address flex1 fs18 fcfff op7">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright  fs16  dfr flex-center ">
      © Aparto 2021. All Right Reserved. Privacy Policy
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      infoList: [
        {
          title: "2130 Fulton Street, Chicago,IL 94117-1080 USA",
          type: "address"
        },
        {
          title: "(856) 803-8041",
          type: "tel"
        },
        {
          title: "tom@nascent.health.com",
          type: "email"
        }
      ],
      shareIcon: [
        {
          name: "twitter",
          href: "https://twitter.com/",
          icon: require("../assets/images/wechat.png"),
          qrcode: require("../assets/images/wechat-qrcode.jpeg")
        }
        // {
        //   name: "facebook",
        //   href: "https://facebook.com/",
        //   icon: require("../assets/images/facebook.png")
        // },
        // {
        //   name: "instagram",
        //   href: "https://instagram.com/",
        //   icon: require("../assets/images/in.png")
        // }
      ],
      langText: {
        leftDesc:
          "Allsethomeus is a professional web design agency that creates websites for corporate and individual customers. We also design and develop brands & web apps with intuitive user experiences and interactions, which work beautifully across all devices. If you are looking for a one-of-a-kind website for your company, we will be happy to design it for you.",
        contactus: "CONTACT US",
        telDesc: "24/7 IN TOUCH"
      },
      lang: "EN",
      leftInfo: {
        icon: "",
        desc: ""
      }
    };
  },
  async created() {
    await this.initLang();
    await this.initPage();
  },
  mounted() {
    this.$bus.$on("changeLang", () => {
      this.initLang();
      this.initPage();
    });
  },
  methods: {
    initPage() {
      this.initContacts();
      this.initAboutUs();
    },
    initLang() {
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.footer;
      } else if (lang == "CN") {
        this.langText = this.$CN.footer;
      }
      this.lang = lang;
    },
    async initContacts() {
      const resp = await this.$API
        .get("contacts", "", false)
        .then(resp => resp);

      if (resp.code == 1000) {
        const info = resp.data.contact[0];
        if (info) {
          if (this.lang == "CN") {
            this.infoList[0].title = info.address_cn;
          } else {
            this.infoList[0].title = info.address;
          }

          this.infoList[1].title = info.tel;
          this.infoList[2].title = info.email;
        }
      }
    },
    async initAboutUs() {
      const resp = await this.$API.get("aboutUs", "", false).then(resp => resp);

      if (resp.code == 1000) {
        const info = resp.data.synopses[0];
        if (info) {
          this.leftInfo.icon = info.image;
          if (this.lang == "CN") {
            this.leftInfo.desc = info.description;
          } else {
            this.leftInfo.desc = info.en_description;
          }
        }
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.footer {
  background: #22252a;
  width: 100%;
  min-height: 446px;
  overflow: hidden;
  box-sizing: border-box;
  .footer-content {
    min-height: 392px;
    box-sizing: border-box;
    width: 1200px;
    max-width: 90%;
    margin: 0 auto;
    padding-top: 50px;
    .footer-left {
      flex: 1;

      .logo-wrap {
        width: 191px;
        height: 60px;
      }
      .logo-desc {
        width: 500px;
        max-width: 100%;
        margin: 30px 0 73px;
      }
      .share-icon-box {
        .share-icon {
          &:hover {
            .qrcode-wrrap {
              opacity: 1;
            }
          }
        }
        .qrcode-wrrap {
          bottom: 120%;
          left: 0;
          width: 140px;
          height: 140px;
          opacity: 0;
          transition: all 0.3s;
          z-index: -1;
        }
        .share-icon {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.15);
          margin-right: 14px;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.2);
            background: #87bcdc;
          }
          .contact-icon-us {
            height: 18px;
            width: 18px;
            object-fit: contain;
          }
        }
      }
    }
    .footer-right {
      flex: 1;
      padding-left: 110px;
      .contact-us-box {
        margin-top: 8px;
        .my-info {
          margin-top: 34px;
        }
        .icon-box {
          width: 18px;
          height: 18px;
          margin-right: 18px;
        }
      }
      .desc {
        margin-top: 8px;
      }
      .can-hover:hover {
        color: #87bcdc;
      }
    }
  }
  .footer-copyright {
    background: #1b1b1b;
    height: 54px;
    color: rgba(255, 255, 255, 0.7);
  }
  .content-box {
    margin: 40px 0;
    .my-info {
      margin-right: 40px;
    }
  }
}
@media screen and (max-width: 767px) {
  .footer {
    overflow: unset;

    .footer-content {
      flex-direction: column;
      padding-top: 30px;
      .footer-left {
        .logo-desc {
          margin: 40px 0;
        }
      }
      .footer-right {
        padding-left: 0 !important;
        margin: 30px 0;
      }
    }
  }
}
</style>
