<template>
  <div class="order-progress dfr">
    <div class="left dfc flex-center">
      <div class="no fs14 fc999 ">NO. {{ detail.order_no }}</div>
      <div class="active fs24 fb ">
        {{ statusMap[detail.status] }}
      </div>
      <div
        @click="cancelOrder(detail.id)"
        v-if="detail.status == 1"
        class="no-btn pointer fcfff fs14"
      >
        Withdraw
      </div>
      <div v-if="detail.status == 2" class="no-btn pointer fcfff fs14">
        Advance deposit
      </div>
    </div>
    <!-- cancel  -->
    <div v-if="detail.status == 5" class="right dfr cross-center">
      <div class="step-1 step">
        <div class="step-default">
          <div class="step-number">1</div>
        </div>
        <div class="desc-f">Submitted</div>
      </div>
      <div class="line"></div>
      <div class="step-2 step">
        <div class="step-default">
          <div class="step-number">2</div>
        </div>
        <div class="desc-f">In Review</div>
        <div class="desc-s"></div>
      </div>
      <div class="line"></div>
      <div class="step-3 step">
        <div class="step-default">
          <div class="step-number">3</div>
        </div>
        <div class="desc-f">Succeed</div>
        <div class="desc-s"></div>
      </div>
      <div class="line"></div>
      <div class="step-4 step">
        <div class="step-default">
          <div class="step-number">4</div>
        </div>
        <div class="desc-f">Waiting Move</div>
        <div class="desc-s"></div>
      </div>
      <div class="line"></div>
      <div class="step-5 step">
        <div class="step-default">
          <div class="step-number">5</div>
        </div>
        <div class="desc-f">Checked in</div>
        <div class="desc-s"></div>
      </div>
    </div>
    <!-- 其他状态 -->
    <div v-else class="right dfr cross-center">
      <div
        v-if="detail.status == 2"
        class="success-desc dfr cross-center fs14 fc000"
      >
        <div class="arrow-right ">
          <img class="w100" src="../../assets/images/arrow-right.png" alt="" />
        </div>
        After the deposit payment is successful, you can wait to move in
      </div>
      <div
        :class="[
          detail.status == 0 ? 'active' : '',
          detail.status >= 1 ? 'success' : ''
        ]"
        class="step-1 step"
      >
        <div class="step-default">
          <div class="step-number">1</div>
          <img
            v-if="detail.status >= 1"
            src="../../assets/images/order-success.png"
            alt=""
            class="success-img"
          />
        </div>
        <div class="desc-f">Submitted</div>
        <div class="desc-s">2021-02-24 08:05:21</div>
      </div>
      <div :class="[detail.status >= 1 ? 'active' : '']" class="line"></div>
      <div
        :class="[
          detail.status == 1 ? 'active' : '',
          detail.status >= 2 ? 'success' : ''
        ]"
        class="step-2 step"
      >
        <div class="step-default">
          <div class="step-number">2</div>
          <img
            v-if="detail.status >= 2"
            src="../../assets/images/order-success.png"
            alt=""
            class="success-img"
          />
        </div>
        <div class="desc-f">In Review</div>
        <div class="desc-s"></div>
      </div>
      <div :class="[detail.status >= 2 ? 'active' : '']" class="line"></div>
      <div
        :class="[
          detail.status == 2 ? 'active' : '',
          detail.status >= 3 ? 'success' : ''
        ]"
        class="step-3 step"
      >
        <div class="step-default">
          <div class="step-number">3</div>
          <img
            v-if="detail.status >= 3"
            src="../../assets/images/order-success.png"
            alt=""
            class="success-img"
          />
        </div>
        <div class="desc-f">Succeed</div>
        <div class="desc-s"></div>
      </div>
      <div :class="[detail.status >= 3 ? 'active' : '']" class="line"></div>
      <div
        :class="[
          detail.status == 3 ? 'active' : '',
          detail.status >= 4 ? 'success' : ''
        ]"
        class="step-4 step"
      >
        <div class="step-default">
          <div class="step-number">4</div>
          <img
            v-if="detail.status >= 4"
            src="../../assets/images/order-success.png"
            alt=""
            class="success-img"
          />
        </div>
        <div class="desc-f">Waiting Move</div>
        <div class="desc-s"></div>
      </div>
      <div :class="[detail.status >= 4 ? 'active' : '']" class="line"></div>
      <div
        :class="[
          detail.status == 4 ? 'active' : '',
          detail.status >= 5 ? 'success' : ''
        ]"
        class="step-5 step"
      >
        <div class="step-default">
          <div class="step-number">5</div>
          <img
            v-if="detail.status >= 5"
            src="../../assets/images/order-success.png"
            alt=""
            class="success-img"
          />
        </div>
        <div class="desc-f">Checked in</div>
        <div class="desc-s"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object
  },
  data() {
    return {
      statusMap: {
        // 0: "已提交",
        // 1: "审核中",
        // 2: "申请成功",
        // 3: "等待入住",
        // 4: "已入住",
        // 5: "已取消"
        0: "Submitted",
        1: "In Review",
        2: "Succeed",
        3: "Waiting Move",
        4: "Checked in",
        5: "Cancel"
      }
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  },
  methods: {
    async cancelOrder(id) {
      const resp = await this.$API
        .post("cancelOrder", { id })
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$message({
          message: "cancel success",
          type: "success"
        });
        this.$emit("initpage");
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.order-progress {
  width: 1200px;
  max-width: 100%;
  margin: 22px auto;
  min-height: 218px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding: 30px 0;
  box-sizing: border-box;

  .left {
    width: 300px;
    border-right: 1px solid #eee;
    box-sizing: border-box;
    height: 158px;
    .active {
      color: #27a900;
      margin-top: 30px;
    }
    .no-btn {
      margin-top: 30px;
      padding: 8px 20px;
      display: inline-block;

      background: #87bcdc;
      border-radius: 4px;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    }
  }
  .right {
    padding-left: 66px;
    padding-bottom: 40px;
    position: relative;
    .success-desc {
      position: absolute;
      left: 30px;
      top: 0;
      .arrow-right {
        width: 12px;
        transform: rotate(180deg);
        margin-right: 8px;
      }
    }
    .step {
      width: 42px;
      height: 42px;
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &.active {
        .step-default {
          width: 42px;
          height: 42px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #e3f4e0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .step-number {
          color: #fff;
          background: #1ba700;
          border: 3px solid #1ba700;
          position: relative;
          z-index: 2;
        }
        .desc-f {
          color: #1ba700;
        }
        .desc-s {
          color: #1ba700;
        }
      }
      &.success {
        .step-number {
          color: transparent;
          background: #87bcdc;
          border: 3px solid #87bcdc;
        }
        .success-img {
          width: 32px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .desc-f {
          color: #87bcdc;
        }
        .desc-s {
          color: #87bcdc;
        }
      }
      .step-number {
        width: 32px;
        height: 32px;
        border: 3px solid #dfdfdf;
        box-sizing: border-box;
        border-radius: 50%;
        color: #ccc;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .desc-f {
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        color: #999;
      }
      .desc-s {
        width: 70px;
        font-size: 12px;
        position: absolute;
        top: 90px;
        left: 50%;
        transform: translateX(-50%);
        color: #87bcdc;
        text-align: center;
      }
    }
    .line {
      width: 123px;
      height: 4px;
      background: #dfdfdf;
      border-radius: 2px;
      &.active {
        background: #87bcdc;
      }
    }
  }
}
</style>
