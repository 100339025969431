<template>
  <div class="detail-tab-inner  ">
    <div class="amenity-list ">
      <div
        v-for="(item, index) in detail.facility"
        :key="index"
        class="amenity-item dfr "
      >
        <div class="icon-box dfr flex-center">
          <img :src="item.image" alt="" class="icon " />
        </div>
        <div class="amenity-info flex1">
          <div class="amenity-title fs18 fc000 fw500">
            {{ item.title }}
          </div>
          <div class="amenity-item-list">
            <div
              v-for="(amenity, aindex) in item.content"
              :key="aindex"
              class="amenity-item-cell dfr cross-start fs16 fc333"
            >
              <div class="dot"></div>
              <div class="flex1">
                {{ amenity.func }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object
  },
  data() {
    return {};
  },
  created() {},

  mounted() {},
  methods: {},
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.title-box {
  margin-top: 20px;
}
.detail-tab-inner {
  .title {
    margin-top: 50px;
  }
  .amenity-list {
    // transform: translateY(-21px);
    margin-top: 30px;
    // display: flex;
    // flex-flow: column wrap;
    column-count: 2;

    column-width: 240px;

    column-gap: 20px;
    .amenity-item {
      margin: 44px 0px 0 0;
      // margin: 44px 66px 0 0;
      width: 330px;
      break-inside: avoid;
      &:first-child {
        margin-top: 0;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
      .icon-box {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #87bcdc;
        overflow: hidden;
        margin-right: 14px;
        .icon {
          width: 16px;
        }
      }
      .amenity-info {
        .amenity-item-list {
          margin-top: 28px;
          .amenity-item-cell {
            margin-top: 12px;
          }
          .dot {
            width: 3px;
            height: 3px;
            background: #666666;
            margin-right: 12px;
            transform: translateY(8px);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .detail-tab-inner {
    .title {
      margin-top: 20px;
      font-size: 28px;
    }
    .amenity-list {
      .amenity-item {
        margin: 40px 0 0 0;
        width: 100%;
      }
    }
  }
}
</style>
