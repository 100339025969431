// import router from "../router/index.js";
import axios from "axios";
import store from "store";
const config = require("../../config");
import { Message } from "element-ui";
import { logOut } from "./util";
const apiMap = {
  populars: "/api/Home/popularListings",
  apartmentList: "/api/Apartment/apartmentList",
  detail: "/api/Apartment/detail",
  register: "/api/Login/register",
  userInfo: "/api/Login/info",
  codeLogin: "/api/Login/codeLogin",
  orderList: "/api/Order/orderList",
  sendMessage: "/api/Order/applyApartment",
  sendTel: "/api/Home/service",
  orderDetail: "/api/Order/orderDetail",
  cancelOrder: "/api/Order/withDraw",
  updateInfo: "/api/Login/updateInfo",
  position: "/api/Home/surroundings",
  surrounding: "/api/Home/around",
  agreement: "/api/Home/agreement",
  homeWord: "/api/Home/homeWord",
  contacts: "/api/Home/contactUs",
  emailSend: "/api/Login/sendMailVerify",
  forget: "/api/Login/forgetPassword",
  aboutUs: "/api/Home/synopses",
  apartmentTag: "/api/Home/sites",
  sendMeMessage: "/api/Home/sendEmailContactUs",
  changePassword: "/api/Login/changePassword",
  serviceList: "/api/Home/serveMenus",
  shopLink: "/api/Home/shopUrl"
};

/**
 * 后台数据返回statusCodeMap
 */
const statusCodeMap = {
  OK: 1000,
  JwtError: new Set([2004, 2005, 2006, 2007])
};

/**
 * 发起axios请求
 * @param {*string} url 请求地址
 * @param {*staring} methods 请求方法
 * @param {*object} data 携带的数据
 * @param {*string} JWT header添加的 jwt
 */
const _axios = (url, methods, data, JWT) => {
  // 处理请求方式
  let _data = "";
  let _params = data;
  if (methods !== "get") {
    _data = data;
    _params = "";
  }

  // 判断是否需要token
  let httpHeaders = "";

  if (JWT) {
    httpHeaders = { Authorization: "bearer " + JWT };
  }

  return axios({
    url,
    method: methods,
    data: _data,
    params: _params,
    responseType: "json",
    timeout: 10000,
    headers: httpHeaders
  });
};

/**
 * JWT失效的处理
 */

const JwtErrorHandle = async resp => {
  if (resp && resp.data && resp.data.msg) {
    Message(resp.data.msg);
  } else {
    Message.error("此接口不需要JWT");
  }

  logOut();
};

/**
 * 处理请求
 * @param {*string} apiKey
 * @param {*string} methods
 * @param {*object} data
 * @param {*boolean} needJWT
 * @param {*fn} resolve
 * @param {*fn} reject
 */
const handelRequest = (apiKey, methods, data, needJWT, resolve, reject) => {
  // 请求方法
  const request = (JWT = "") => {
    const url = `${config.domain}${apiMap[apiKey]}`;

    _axios(url, methods, data, JWT)
      .then(async resp => {
        // 如果返回的code为1000 将数据返回
        if (resp.data.code == statusCodeMap.OK) {
          resolve(resp.data);
          // JWT错误处理
        } else if (statusCodeMap.JwtError.has(resp.data.code)) {
          JwtErrorHandle(resp);
        } else {
          reject(resp.data);
          Message(resp.data.msg);
        }
      })
      .catch(err => {
        const errInfo = {
          msg: err
        };
        reject(errInfo);
      });
  };

  // 定义JWT
  let JWT = store.get("JWT");

  // 判断是否存在apikey
  if (!apiMap[apiKey]) {
    Message(`请求接口未添加---apiKey：${apiKey}`);
    const err = {
      msg: `请求接口未添加---apiKey：${apiKey}`
    };
    reject(err);
    return;
  }

  // 判断是否需要JWT
  if (needJWT) {
    // 是否有JWT
    if (JWT) {
      request(JWT);
    } else {
      logOut();
    }
  } else {
    request();
  }
};

const API = {
  beforeRequest: () => {},
  get: (apiKey, data = "", needJWT = true) => {
    return new Promise((resolve, reject) => {
      handelRequest(apiKey, "get", data, needJWT, resolve, reject);
    });
  },
  post: (apiKey, data = "", needJWT = true) => {
    return new Promise((resolve, reject) => {
      handelRequest(apiKey, "post", data, needJWT, resolve, reject);
    });
  },
  afterRequest: () => {},
  axios
};

export default API;
