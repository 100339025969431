<template>
  <div
    v-if="detail"
    @click="goDetail"
    :class="[show ? 'show' : '']"
    class="detail-card-max-wrap pointer"
  >
    <div v-if="!show" class="title dfr cross-center main-between">
      <div
        class="status-text fs16 fb fctheme"
        :class="[
          detail.status == 1 ? 'review' : '',
          detail.status == 5 ? 'cancle' : ''
        ]"
      >
        {{ statusMap[detail.status] }}
      </div>
      <div class="time-no dfr fs14 fc999">
        <div class="time">{{ detail.created_at }}</div>
        <div class="no">NO. {{ detail.order_no }}</div>
      </div>
    </div>
    <div class="content dfr">
      <div class="left-box dfr">
        <div class="left-icon-bxo">
          <img
            :src="detail.apartment.image"
            class="w100 h100 object-cover"
            alt=""
          />
        </div>
        <div class="info-box dfc main-between">
          <div :class="[show ? 'show' : '']" class="info">
            <div class="popular-title fs20 fc333 fb">
              {{ detail.apartment.name }}
            </div>
            <div class="price-box fs14 fc333">
              ${{ detail.apartment.floor_price | formatPrice }} - ${{
                detail.apartment.top_price | formatPrice
              }}
            </div>
            <div class="address-info dfr cross-start fs14 fc666">
              <div class="address-icon">
                <img
                  src="../../assets/images/address.png"
                  class="w100 "
                  alt=""
                />
              </div>
              <div class="address">
                {{ detail.apartment.address }}
              </div>
            </div>
          </div>
          <div v-if="!show">
            <div
              v-if="detail.status == 0 || detail.status == 1"
              class="pay-btn dfr flex-center pointer fs14 fcfff"
            >
              Advance deposit
            </div>
          </div>
        </div>
      </div>
      <div class="right-box fs14">
        <div class="info-title fs16 fc000 fb">
          Reservation Information
        </div>
        <div class="info-cell ">
          <div class="cell-left fc666">Name</div>
          <div class="cell-right fc000">
            {{ detail.first_name }} {{ detail.last_name }}
          </div>
        </div>
        <div class="info-cell ">
          <div class="cell-left fc666">Room Type</div>
          <div class="cell-right fc000">{{ detail.room_type }} Bedrooms</div>
        </div>
        <div class="info-cell ">
          <div class="cell-left fc666">Email Address</div>
          <div class="cell-right fc000">
            {{ detail.email }}
          </div>
        </div>
        <div class="info-cell ">
          <div class="cell-left fc666">Move-in Date</div>
          <div class="cell-right fc000">
            {{ detail.move_date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object,
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statusMap: {
        // 0: "已提交",
        // 1: "审核中",
        // 2: "申请成功",
        // 3: "等待入住",
        // 4: "已入住",
        // 5: "已取消"
        0: "Submitted",
        1: "In Review",
        2: "Succeed",
        3: "Waiting Move",
        4: "Checked in",
        5: "Cancel"
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    goDetail() {
      this.$go("order", { id: this.detail.id });
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.detail-card-max-wrap {
  width: 870px;
  height: 237px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  margin-top: 20px;
  &.show {
    width: 100%;
    height: auto;
    border: none;
  }
  .title {
    width: 100%;
    height: 50px;
    background: #f9f9f9;
    border-radius: 6px 6px 0px 0px;
    padding: 0 20px;
    box-sizing: border-box;
    .status-text {
      &.review {
        color: #27a900;
      }
      &.cancle {
        color: #dfdfdf;
      }
    }
    .time {
      margin-right: 30px;
    }
  }
  .content {
    padding: 20px;
    box-sizing: border-box;
    .left-box {
      .left-icon-bxo {
        width: 215px;
        height: 147px;
        border-radius: 6px;
        overflow: hidden;
      }
      .info-box {
        margin-left: 20px;

        .info {
          width: 268px;
          &.show {
            width: 360px;
          }
          .popular-title {
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .price-box {
            height: 17px;
            margin: 8px 0 10px;
          }
          .address-info {
            .address-icon {
              width: 12px;
              height: 12px;
              margin-right: 6px;
            }
            .address {
              width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .pay-btn {
          padding: 8px 12px 7px;
          width: 140px;
          height: 32px;
          box-sizing: border-box;
          background: #87bcdc;
          border-radius: 4px;
          opacity: 0.9;
          transition: all 0.3s;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .right-box {
      border-left: 1px solid #eee;
      padding: 0 30px;
      box-sizing: border-box;
      margin-left: 30px;
      .info-title {
        margin-bottom: 20px;
      }
      .info-cell {
        margin-top: 8px;
        display: flex;
        .cell-left {
          width: 110px;
        }
      }
    }
  }
}
</style>
