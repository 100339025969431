<template>
  <div class="create-account-wrap">
    <div class="title fs30 fc000 text-center fb">
      {{ langText.createPassword }}
    </div>
    <div class="desc fs14 fc999 text-center">
      {{ langText.passwordDesc }}
    </div>
    <!-- email -->
    <div class="email-wrap">
      <div class="fs14 fc333">{{ langText.email }}</div>
      <div class="email-box dfr cross-center por">
        <div class="icon-box dfr flex-center">
          <img
            class="w100  object-cover"
            src="../../assets/images/message.png"
            alt=""
          />
        </div>
        <el-input
          :class="[emailFormatErr ? 'show-err' : '']"
          v-model.trim="email"
          @input="emailBlur"
          :placeholder="langText.email"
          @keyup.enter.native="signUp"
        ></el-input>
      </div>
    </div>
    <div v-if="emailFormatErr" class="emailFormatErr">
      {{ langText.emailErrTip }}
    </div>
    <!-- 邮箱验证码 -->
    <div class=" email-wrap  ">
      <div class="fs14 fc333">{{ langText.forget.emailCode }}</div>
      <div class="email-code-wrap dfr mdfc cross-center main-between">
        <div class="email-box email-code dfr cross-center por">
          <div class="icon-box dfr flex-center">
            <img
              class="w100  object-cover"
              src="../../assets/images/email-code.png"
              alt=""
            />
          </div>
          <el-input
            v-model.trim="code"
            @input="codeBlur"
            :placeholder="langText.forget.emailCodePlaceholder"
            @keyup.enter.native="signUp"
          ></el-input>
        </div>
        <div class="email-btn-box ">
          <el-button
            v-if="isCanSend"
            :disabled="email ? false : true"
            class=" h100"
            @click="sendCode"
            >{{ langText.forget.sendCode }}</el-button
          >
          <el-button v-else disabled class=" h100"
            >{{ langText.forget.repeat }}{{ CD }}s
          </el-button>
        </div>
      </div>
    </div>
    <!-- password -->
    <div class="email-wrap">
      <div class="fs14 fc333">{{ langText.signIn.password }}</div>
      <div class="email-box dfr cross-center por">
        <div class="icon-box dfr flex-center">
          <img
            class="w100  object-cover"
            src="../../assets/images/password.png"
            alt=""
          />
        </div>
        <el-input
          :class="[passwordErr ? 'show-err' : '']"
          v-model.trim="password"
          @input="passwordBlur"
          :placeholder="langText.signIn.password"
          @keyup.enter.native="signUp"
          type="password"
          ref="password"
        ></el-input>
      </div>
    </div>
    <div v-if="passwordErr" class="emailFormatErr">
      {{ langText.passwordDesc }}
    </div>

    <!-- secondPassword -->
    <div class="email-wrap">
      <div class="fs14 fc333">{{ langText.confirmPassword }}</div>
      <div class="email-box dfr cross-center por">
        <div class="icon-box dfr flex-center">
          <img
            class="w100  object-cover"
            src="../../assets/images/password.png"
            alt=""
          />
        </div>
        <el-input
          :class="[secondPasswordErr ? 'show-err' : '']"
          name="email"
          v-model.trim="secondPassword"
          @input="secondPasswordBlur"
          :placeholder="langText.confirmPassword"
          @keyup.enter.native="signUp"
          type="password"
        ></el-input>
      </div>
    </div>
    <div v-if="secondPasswordErr" class="emailFormatErr">
      {{ langText.passwordNotRepeat }}
    </div>
    <div class="agree-box dfr cross-center">
      <el-checkbox v-model="checked" @change="checkChange">{{
        langText.iHaveRead
      }}</el-checkbox>
      <span @click="agreePolicy" class="Policy pointer fw500">{{
        langText.policy
      }}</span>
    </div>
    <!-- signUp -->
    <div class="next" @click="signUp">
      <el-button class="w100 h100" type="primary" :disabled="disabled">{{
        langText.signIn.title
      }}</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    createInfo: Object,
    showFocus: Boolean,
    langText: Object,
    type: String
  },
  data() {
    return {
      disabled: true,
      password: "",
      secondPassword: "",
      passwordErr: false,
      secondPasswordErr: false,
      checked: false,
      innerVisible: false,
      email: "",
      code: "",
      CD: 60,
      CDTimer: null,
      isCanSend: true,
      emailFormatErr: false
    };
  },

  created() {},
  mounted() {
    this.$nextTick(() => {
      this.$refs.password.focus();
    });
  },
  methods: {
    codeBlur() {
      this.checkIsFill();
    },
    emailBlur() {
      this.checkIsFill();
      if (this.email && this.validateEmail(this.email)) {
        this.emailFormatErr = false;
      }
    },
    passwordBlur() {
      this.checkIsFill();
      if (this.password && this.password.length >= 8) {
        this.passwordErr = false;
      }
    },
    secondPasswordBlur() {
      this.checkIsFill();
      if (this.secondPassword && this.secondPassword == this.password) {
        this.secondPasswordErr = false;
      }
    },
    checkChange() {
      this.checkIsFill();
    },
    checkIsFill() {
      if (
        this.email &&
        this.code &&
        this.password &&
        this.secondPassword &&
        this.checked
      ) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    agreePolicy() {
      this.$bus.$emit("showAgree");
    },
    async signUp() {
      if (!this.email) {
        return;
      }
      if (this.email && !this.validateEmail(this.email)) {
        this.emailFormatErr = true;
        return;
      }
      if (!this.code) {
        return;
      }
      if (this.password.length < 8) {
        this.passwordErr = true;
        return;
      }
      if (this.password != this.secondPassword) {
        this.secondPasswordErr = true;
        return;
      }
      if (!this.checked) {
        return;
      }
      let apiName = "";
      let data = null;
      if (this.type == "forget") {
        apiName = "forget";
        data = {
          password: this.password,
          confirm: this.secondPassword,
          email: this.email,
          verify: this.code
        };
      } else {
        let { first_name, last_name, tel } = this.createInfo;
        apiName = "register";

        data = {
          password: this.password,
          confirm: this.secondPassword,
          first_name,
          last_name,
          email: this.email,
          tel,
          verify: this.code
        };
      }

      const resp = await this.$API
        .post(apiName, data, false)
        .then(resp => resp);

      if (resp.code == 1000) {
        if (this.type == "forget") {
          this.$emit("forgetOK");
        } else {
          await this.$localStore.set("JWT", resp.data.token);
          await this.$localStore.set("isLogin", true);
          this.$emit("signUpOk");
          this.$bus.$emit("updateInfo");
        }
      }
      // this.$emit("nextHandle", data);
    },
    validateEmail(email) {
      // 邮箱验证正则
      var reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      return reg.test(email);
    },
    async sendCode() {
      this.CD = 60;
      this.CDTimer = setInterval(() => {
        if (this.CD <= 0) {
          this.CDTimer = null;
          clearInterval(this.CDTimer);
          this.isCanSend = true;
          return;
        }
        this.isCanSend = false;
        this.CD--;
      }, 1000);
      const resp = await this.$API
        .post("emailSend", { email: this.email }, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$message({
          type: "success",
          message: this.langText.sendCodeSuccess
        });
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="scss">
.agree-box {
  margin: 20px 0;
}
.Policy {
  color: #87bcdc;
  font-size: 14px;
  text-decoration: underline;
  margin-left: 10px;
}
.create-account-wrap {
  .desc {
    margin-top: 6px;
  }
  .user-name-box {
    margin: 50px 0 0;
    .name-box {
      &.first {
        margin-right: 12px;
      }
      .name {
        width: 197px;
        height: 50px;
        box-sizing: border-box;
        margin-top: 12px;
      }
      .icon-box {
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        margin-right: 18px;
        z-index: 2;
      }
    }
  }
  .email-wrap {
    margin: 20px 0 0;
    .email-box {
      width: 406px;
      height: 50px;
      box-sizing: border-box;
      margin-top: 12px;
    }
    .icon-box {
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      margin-right: 18px;
      z-index: 2;
    }
  }
  .emailFormatErr {
    color: #fb7373;
    margin-top: 8px;
  }
  .next {
    width: 406px;
    height: 50px;
    margin-top: 20px;
  }
  .register-wrap {
    margin-top: 20px;
    .go-login {
      color: #4e748b;
      opacity: 0.8;
      text-decoration: underline;
      margin-left: 8px;
      &:hover {
        opacity: 1;
      }
    }
  }
  .divider {
    margin-top: 40px;
  }
  .login-other-wrap {
    margin-top: 20px;
    .login-inner-box {
      width: 197px;
      height: 50px;
      border-radius: 6px;
      border: 1px solid #eaeaea;
      transition: all 0.3s;
      &:hover {
        border-color: #87bcdc;
      }
      .google {
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }
      .facebook {
        width: 12px;
        height: 20px;
        margin-right: 20px;
      }
    }
  }
}
</style>
