import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/css/index.scss";

// 引入全局组件注册
import componentRegister from "./utils/componentsImport";

// 引入第三方组件
import "./libs/element";
import "./libs/swiper";
import preview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
// Vue.use(preview);
var option = {};
Vue.use(preview, option);

// 引入全局方法
import "./utils/vuePrototypeAdd";

// 全局Vue过滤器
import * as filterObj from "./utils/vueFilter";
Object.keys(filterObj).forEach(key => Vue.filter(key, filterObj[key]));

Vue.config.productionTip = false;

new Vue({
  componentRegister,
  router,
  render: h => h(App)
}).$mount("#app");
