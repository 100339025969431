const mainCity = {
  // eslint-disable-next-line no-sparse-arrays
  city: [
    "Abilene",
    "Akron",
    "Albany",
    "Alexandria",
    "Allentown",
    "Alpharetta",
    "Alton",
    "Altoona",
    "Alturas",
    "Alva",
    "Amarillo",
    "Americus",
    "Ames",
    "Anaheim",
    "Anderson",
    "Annapolis",
    "AnnArbor",
    "Anniston",
    "Antioch",
    "Arcadia",
    "Ardmore",
    "Arlington",
    "Asheboro",
    "Asheville",
    "Ashland",
    "Aspen",
    "Astoria",
    "Athens",
    "Atlanta",
    "Atlantasuburbs",
    "AtlanticBeach",
    "AtlanticCity",
    "Auburn",
    "Augusta",
    "Aurora",
    "Austin",
    "AvonPark",
    "Bainbridge",
    "Bakersfield",
    "Baltimore",
    "Barrie",
    "Barstow",
    "BatonRouge",
    "BattleCreek",
    "Beaumont",
    "Beaverton",
    "Bellaire",
    "Bellevue",
    "Bellingham",
    "Beloit",
    "Bend",
    "Berkeley",
    "Binghamton",
    "Birmingham",
    "Blacksburg",
    "Blanding",
    "Bloomington",
    "BocaRaton",
    "Boston",
    "Boulder",
    "BowlingGreen",
    "Brentwood",
    "Bridgeport",
    "Bristol",
    "Bronx",
    "Brooklyn",
    "Brownsville",
    "Brunswick",
    "Buckeye",
    "Buffalo",
    "Burbank",
    "Cairo",
    "Cambridge",
    "Camden",
    "Canton",
    "CarsonCity",
    "CedarRapids",
    "Champaign-Urbana",
    "Chandler",
    "Charleston",
    "Charlotte",
    "Charlottesville",
    "Chattanooga",
    "CherryHill",
    "Chicago",
    ,
    "Chicagosuburbs",
    "Chico",
    "Chicoutimi",
    "Cincinnati",
    "Clearwater",
    "Cleveland",
    "Colby",
    "ColoradoSprings",
    "Columbia",
    "Columbus",
    "Concord",
    "Cookeville",
    "Cooksville",
    "Coolidge",
    "CorpusChristi",
    "Corvallis",
    "CouncilBluffs",
    "Creston",
    "Cumberland",
    "Dallas",
    "Danville",
    "Davenport",
    "Dayton",
    "DeerPark",
    "DelRio",
    "Denton",
    "Denver",
    "DesMoines",
    "Detroit",
    "DodgeCity",
    "Downey",
    "Dryden",
    "Dubuque",
    "Duluth",
    "Durham",
    "EastSt.Louis",
    "EauClaire",
    "Edmonds",
    "ElDorado",
    "Elizabeth",
    "Elmira",
    "ElPaso",
    "Ely",
    "Endicott",
    "Enid",
    "Erie",
    "Eugene",
    "Eureka",
    "Evanston",
    "Evansville",
    "Everett",
    "Fairfax",
    "Fayetteville",
    "Findlay",
    "Fitchburg",
    "Flint",
    "Florence",
    "Flushing",
    "FortCollins",
    "FortDodge",
    "FortLauderdale",
    "FortMyers",
    "FortSmith",
    "FortWayne",
    "FortWilliam",
    "FortWorth",
    "Frankfort",
    "Frederick",
    "Fresno",
    "Gadsden",
    "Gainesville",
    "Galveston",
    "Garland",
    "Gary",
    "Gettysburg",
    "Glendale",
    "Gloversville",
    "Goodland",
    "GrandIsland",
    "GrandJunction",
    "GrandPrairie",
    "GrandRapids",
    "GreenBay",
    "Greensboro",
    "Greenville",
    "Guelph",
    "Gulfport",
    "Hackensack",
    "Hagerstown",
    "Hamilton",
    "Hammond",
    "Hannibal",
    "Harrisburg",
    "Hartford",
    "Hastings",
    "Hatteras",
    "Hattiesburg",
    "Hempstead",
    "Herndon",
    "Hickory",
    "Hillsboro",
    "HiltonHeadIsland",
    "Hoboken",
    "HollySprings",
    "Hopkinsville",
    "Houma",
    "Houston",
    "Huntsville",
    "Hutchinson",
    "Hyannis",
    "Independence",
    "Indianapolis",
    "Irvine",
    "Irving",
    "Ithaca",
    "Jackson",
    "Jacksonville",
    "JeffersonCity",
    "Jellico",
    "JerseyCity",
    "Johnstown",
    "Joliet",
    "Jonesboro",
    "Joplin",
    "Kalamazoo",
    "KansasCity",
    "Kelowna",
    "Kenora",
    "Kenosha",
    "Kensee",
    "Kent",
    "KeyWest",
    "Kingston",
    "Kissimmee",
    "Kitchener",
    "Knoxville",
    "Kokomo",
    "LabradorCity",
    "LaCrosse",
    "Lafayette",
    "Laguna",
    "LakeCharles",
    "Lancaster",
    "Lansing",
    "LaSalle",
    "LasVegas",
    "Latrobe",
    "Lawrence",
    "Lawton",
    "Lexington",
    "Lima",
    "Lincoln",
    "LittleRock",
    "Lloydminster",
    "London",
    "LongBeach",
    "LongBranch",
    "LongIsland",
    "LosAltos",
    "LosAngeles",
    "Lot",
    "Louisville",
    "Lubbock",
    "Ludington",
    "Lufkin",
    "Macon",
    "Madison",
    "Manchester",
    "Manhattan",
    "Mankato",
    "Manorville",
    "MapleGrove",
    "Marietta",
    "Marquette",
    "MasonCity",
    "McAlester",
    "McAllen",
    "Memphis",
    "Mentor",
    "Meriden",
    "Methuen",
    "Miami",
    "Middletown",
    "MileHouse",
    "Milwaukee",
    "Minneapolis",
    "Mississauga",
    "Moab",
    "Mobile",
    "Modesto",
    "Monroe",
    "Monterey",
    "Montgomery",
    "Montreal",
    "Moorhead",
    "Morehead",
    "MountPleasant",
    "Muskegon",
    "Muskogee",
    "MyrtleBeach",
    "Naperville",
    "Naples",
    "Nashville",
    "Nevada",
    "Newark",
    "NewBedford",
    "NewBrunswick",
    "NewCastle",
    "NewHaven",
    "NewOrleans",
    "NewportNews",
    "NewRoads",
    "NewYorkCity",
    "NiagaraFalls",
    "Niguel",
    "Norfolk",
    "NorthBay",
    "NorthernDetroitsuburbs",
    "NorthHollywood",
    "NorthMiami",
    "NorthPlatte",
    "Norwalk",
    "Norwood",
    "OakBrookTerrace",
    "Oakland",
    "Oberlin",
    "Ogden",
    "OklahomaCity",
    "Olympia",
    "Omaha",
    "Oneida",
    "O'Neill",
    "Orlando",
    "Oswego",
    "Ottawa",
    "Owensboro",
    "PaineBluff",
    "PalmSprings",
    "PaloAlto",
    "PanamaCity",
    "Pangnirtung",
    "Paris",
    "Pasadena",
    "Pascagoula",
    "Paterson",
    "Peabody",
    "Peekskill",
    "Pendleton",
    "Pensacola",
    "Peoria",
    "Philadelphia",
    "Philadelphiasuburbs",
    "Phillipsburg",
    "Phoenix",
    "Pittsburgh",
    "Pittsfield",
    "Plano",
    "Platteville",
    "Pleasanton",
    "Portland",
    "Poughkeepsie",
    "PrinceGeorge",
    "Provo",
    "Pueblo",
    "Quebec",
    "Queens",
    "Racine",
    "Raleigh",
    "Reading",
    "RedWing",
    "Regina",
    "Reno",
    "Richfield",
    "Richmond",
    "Rimouski",
    "Riverside",
    "Roanoke",
    "Rochester",
    "Rockford",
    "RockHill",
    "RockIsland",
    "Rockville",
    "Rome",
    "Sacramento",
    "SaintGeorge",
    "SaintPaul",
    "Salem",
    "Salina",
    "Salinas",
    "Salisbury",
    "SaltLakeCity",
    "SanAntonio",
    "SanBernardino",
    "SanDiego",
    "SanFernando",
    "SanFrancisco",
    "SanJose",
    "SantaBarbara",
    "SantaRosa",
    "Sarasota",
    "Saskatoon",
    "SaultSte.Marie",
    "SaultSteMarie",
    "Savannah",
    "Saxton",
    "Schenectady",
    "Scottsbluff",
    "Scottsdale",
    "Scranton",
    "Seattle",
    "Sebastian",
    "Sherman",
    "Shreveport",
    "SiouxCity",
    "SolanaBeach",
    "Sonoma",
    "SouthBend",
    "SouthPasadena",
    "SouthSanFrancisco",
    "Spartanburg",
    "Spokane",
    "Springfield",
    "St.John's",
    "St.Petersburg",
    "StatenIsland",
    "Staunton",
    "StCharles",
    "StCloud",
    "StJoseph",
    "StLouis",
    "Sudbury",
    "Superior",
    "Syracuse",
    "Tacoma",
    "Tallahassee",
    "Tampa",
    "Tempe",
    "TerreHaute",
    "Texarkana",
    "Thomasville",
    "ThunderBay",
    "Toledo",
    "Topeka",
    "Toronto",
    "TraverseCity",
    "Trenton",
    "Troy",
    "Tulsa",
    "Tupelo",
    "Tyler",
    "Union",
    "UnionCity",
    "Uniontown",
    "Utica",
    "Uvalde",
    "Valdosta",
    "Vancouver",
    "Ventura",
    "Victoria",
    "Vineland",
    "Waco",
    "WallaWalla",
    "WalnutCreek",
    "WarnerRobins",
    "Warren",
    "Washington",
    "Waterloo",
    "Watertown",
    "Waterville",
    "Waukegan",
    "Wausau",
    "Waycross",
    "WestBend",
    "Westlake",
    "WestPalmBeach",
    "Weymouth",
    "Wharton",
    "Whitehorse",
    "WhitePlains",
    "Wichita",
    "WichitaFalls",
    "Williamsburg",
    "Williamsport",
    "Winchester",
    "Windsor",
    "Winston-Salem",
    "Worcester",
    "Yakima",
    "Yonkers",
    "Youngstown"
  ],
  states: [
    "Alaska",
    "Alabama",
    "Arkansas",
    "Arizona",
    "California",
    "Colorado",
    "Connecticut",
    "District of Columbia",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Iowa",
    "Idaho",
    "Illinois",
    "Indiana",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Massachusetts",
    "Maryland",
    "Maine",
    "Michigan",
    "Minnesota",
    "Missouri",
    "Mississippi",
    "Montana",
    "North Carolina",
    "North Dakota",
    "Nebraska",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "Nevada",
    "New York",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Virginia",
    "Vermont",
    "Washington",
    "Wisconsin",
    "West Virginia",
    "Wyoming"
  ]
};

export default mainCity;
