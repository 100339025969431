<template>
  <div class="create-account-wrap">
    <div class="title fs30 fc000 text-center fb">
      {{ langText.signIn.title }}
    </div>
    <div class="desc fs14 fc999 text-center">
      {{ langText.desc }}
    </div>
    <!-- email -->
    <div class=" email-wrap first">
      <div class="fs14 fc333">{{ langText.email }}</div>
      <div class="email-box dfr cross-center por">
        <div class="icon-box dfr flex-center">
          <img
            class="w100  object-cover"
            src="../../assets/images/message.png"
            alt=""
          />
        </div>
        <el-input
          name="email"
          v-model.trim="email"
          @input="emailBlur"
          :placeholder="langText.email"
          @keyup.enter.native="signIn"
        ></el-input>
      </div>
    </div>
    <!-- password -->
    <div class="email-wrap">
      <div class="fs14 fc333">{{ langText.signIn.password }}</div>
      <div class="email-box dfr cross-center por">
        <div class="icon-box dfr flex-center">
          <img
            class="w100  object-cover"
            src="../../assets/images/password.png"
            alt=""
          />
        </div>
        <el-input
          v-model.trim="password"
          @input="passwordBlur"
          :placeholder="langText.signIn.password"
          @keyup.enter.native="signIn"
          type="password"
          ref="password"
        ></el-input>
      </div>
    </div>
    <div class="dfr mdfc cross-center main-between fs12">
      <div class="agree-box dfr cross-center">
        <el-checkbox v-model="checked" @change="checkChange"
          >{{ langText.iHaveRead }}
        </el-checkbox>
        <span @click="agreePolicy" class="Policy pointer fw500">{{
          langText.policy
        }}</span>
      </div>
      <div
        @click="showForgetPassword"
        class="self-end forget-password fctheme pointer"
      >
        {{ langText.forgetPassword }}
      </div>
    </div>

    <!-- signIn -->
    <div class="next" @click="signIn">
      <el-button class="w100 h100" type="primary" :disabled="disabled">{{
        langText.signIn.title
      }}</el-button>
    </div>
    <!-- register -->
    <div class="register-wrap dfr">
      <div class="fs14 fcbbb">{{ langText.notRegister }}</div>
      <div class="go-login pointer" @click="goCreate">
        {{ langText.createAccount }}
      </div>
    </div>
    <!-- <el-divider class="fcbbb divider" content-position="center">{{
      langText.orSignWith
    }}</el-divider> -->
    <!-- google facebook -->
    <!-- <div class="login-other-wrap dfr cross-center main-between">
      <div
        v-google-signin-button="clientId"
        class="login-inner-box dfr flex-center pointer"
      >
        <img src="../../assets/images/google.png" alt="" class="google" />
        <span class="fs14 fc333 fb">Google</span>
      </div>
      <div class="login-inner-box dfr flex-center pointer">
        <img
          src="../../assets/images/facebook-active.png"
          alt=""
          class="facebook"
        />
        <span class="fs14 fc333 fb">Facebook</span>
      </div>
    </div> -->
  </div>
</template>
<script>
import "vue-google-signin-button-directive";
// import jsonwebtoken from "jsonwebtoken";

export default {
  props: {
    show: Boolean,
    langText: Object
  },
  data() {
    return {
      disabled: true,
      password: "",
      email: "",
      checked: false,
      clientId:
        "756107412021-t4sfmicdeopje93g06eoropfqf95g1ms.apps.googleusercontent.com"
    };
  },

  created() {},
  mounted() {},
  methods: {
    OnGoogleAuthSuccess(idToken) {
      // 请求后端接口，对登录信息进行校验
      this.GoogleLoginWithIdToken(idToken);
    },
    // OnGoogleAuthFail(error) {},
    // async GoogleLoginWithIdToken(idToken) {
    // let info = jsonwebtoken.decode(idToken);

    // let res = await this.GoogleLoginWithIdToken(idToken);
    // if (res.code === 0) {
    //
    // }
    // },
    // OnGoogleAuthSuccess(idToken) {
    //    //返回第三方结果信息 默认是全token 要用jsonwebtoken 解析
    //
    //   // Receive the idToken and make your magic with the backend
    // },
    emailBlur() {
      this.checkAllFill();
    },
    passwordBlur() {
      this.checkAllFill();
    },
    checkChange() {
      this.checkAllFill();
    },
    goCreate() {
      this.$emit("goCreate");
    },
    showForgetPassword() {
      this.$emit("showForgetPopup");
    },
    checkAllFill() {
      if (this.email && this.password && this.checked) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    async signIn() {
      if (!this.email) {
        return;
      }
      if (!this.password) {
        return;
      }
      if (!this.checked) {
        return;
      }
      const data = {
        email: this.email,
        password: this.password
      };
      const resp = await this.$API
        .post("codeLogin", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        await this.$localStore.set("JWT", resp.data.token);
        await this.$localStore.set("isLogin", true);
        this.$emit("loginOk");
        this.$bus.$emit("updateInfo");
      }
    },
    agreePolicy() {
      this.$bus.$emit("showAgree");
    }
  },
  destroyed() {}
};
</script>
<style lang="scss">
.forget-password {
  text-decoration: underline;
}
.create-account-wrap {
  .desc {
    margin-top: 6px;
  }
  .user-name-box {
    margin: 50px 0 0;
    .name-box {
      &.first {
        margin-right: 12px;
      }
      .name {
        width: 197px;
        height: 50px;
        box-sizing: border-box;
        margin-top: 12px;
      }
      .icon-box {
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        margin-right: 18px;
        z-index: 2;
      }
    }
  }
  .email-wrap {
    margin: 20px 0 0;
    &.first {
      margin-top: 50px;
    }
    .email-box {
      width: 406px;
      height: 50px;
      box-sizing: border-box;
      margin-top: 12px;
    }
    .icon-box {
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      margin-right: 18px;
      z-index: 2;
    }
  }
  .emailFormatErr {
    color: #fb7373;
    margin-top: 8px;
  }
  .next {
    width: 406px;
    height: 50px;
    margin-top: 20px;
  }
  .register-wrap {
    margin-top: 20px;
    .go-login {
      color: #4e748b;
      opacity: 0.8;
      text-decoration: underline;
      margin-left: 8px;
      &:hover {
        opacity: 1;
      }
    }
  }
  .divider {
    margin-top: 40px;
  }
  .login-other-wrap {
    margin-top: 20px;
    .login-inner-box {
      width: 197px;
      height: 50px;
      border-radius: 6px;
      border: 1px solid #eaeaea;
      transition: all 0.3s;
      &:hover {
        border-color: #87bcdc;
      }
      .google {
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }
      .facebook {
        width: 12px;
        height: 20px;
        margin-right: 20px;
      }
    }
  }
}
</style>
