<template>
  <div class="fs16 fw500 fc000 app-header">
    <div v-if="pcWidth" class="pc-header dfr cross-center main-between">
      <div class="img-logo pointer" @click="go('home')">
        <img
          class="w100 h100 object-cover"
          src="../assets/images/logo.png"
          alt=""
        />
      </div>
      <div class="header-list dfr cross-center h100">
        <div class="header-cell dfr h100">
          <!-- 公寓 -->
          <div class="lang-wrap pointer">
            <el-dropdown trigger="click" class="w100 h100">
              <div
                :class="[site ? 'fctheme' : 'fc333']"
                class="el-dropdown-link dfr flex-center w100 h100"
              >
                <div
                  :class="[site ? 'fctheme' : 'fc333']"
                  class="pc-name fs16 "
                >
                  {{ langText.apartmentList }}
                </div>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu v-if="apartmentList.length" slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in apartmentList"
                  :key="index"
                  @click.native="goApartmentList(item)"
                >
                  <span
                    :class="[site == item.site_name ? 'fctheme' : '']"
                    v-if="lang == 'CN'"
                    >{{ item.site_name }}</span
                  >
                  <span
                    :class="[site == item.en_name ? 'fctheme' : '']"
                    v-else
                    >{{ item.en_name }}</span
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 服务 -->
          <div class="lang-wrap pointer">
            <el-dropdown trigger="click" class="w100 h100">
              <div class="el-dropdown-link dfr flex-center w100 h100">
                <div class="pc-name fs16 fc333">
                  {{ langText.service }}
                </div>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in serviceList"
                  :key="index"
                  @click.native="goService(item.url)"
                >
                  <span v-if="lang == 'CN'">{{ item.name }}</span>
                  <span v-else>{{ item.en_name }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 商城  关于我们 -->
          <div
            v-for="(item, index) in headerList"
            :key="index"
            @click="changeHeader(index)"
            class="header-item por h100 dfr pointer flex-center"
            :class="[item.active ? 'active' : '']"
          >
            {{ item.title }}
            <div :class="[item.active ? 'active' : '', 'border-line']"></div>
          </div>
          <!-- 语言 -->
          <div class="lang-wrap pointer">
            <el-dropdown trigger="click" class="w100 h100">
              <div class="el-dropdown-link dfr flex-center w100 h100">
                <div class="pc-name fs16 fc333">{{ langText.language }}</div>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="changeLang('EN')"
                  >EN</el-dropdown-item
                >
                <el-dropdown-item @click.native="changeLang('CN')"
                  >中文</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 登录 个人信息 -->
          <div
            v-if="isLogin"
            class="login-box dfr cross-center pointer fs16 fc000"
          >
            <el-dropdown trigger="click" class="w100 h100">
              <div class="el-dropdown-link dfr flex-center w100 h100">
                <div class="pc-name">
                  {{ langText.hello }}{{ userInfo.name }}
                </div>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="goInfo">{{
                  langText.userInfo
                }}</el-dropdown-item>
                <el-dropdown-item @click.native="goOrderLink">{{
                  langText.order
                }}</el-dropdown-item>
                <el-dropdown-item @click.native="logoutHandle">{{
                  langText.logout
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div v-else class="go-login dfr flex-center fs16 fc000">
            <div
              @click="showLoginHandle"
              class="go-sign-box dfr flex-center pointer"
            >
              <!-- <div class="sign-img"></div> -->
              {{ langText.login }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="phone-header flex-wrap  dfr cross-center main-between por"
      @touchmove.prevent
    >
      <div class="mobile-wrap-header dfr cross-center main-between w100">
        <div class="img-logo pointer" @click="go('home')">
          <img
            class="w100 h100 object-cover"
            src="../assets/images/logo.png"
            alt=""
          />
        </div>
        <div @click="isOpen = !isOpen" class="list-box fs0">
          <img
            v-if="!isOpen"
            src="../assets/images/list.png"
            alt=""
            class="w100 "
          />
          <img
            v-else
            src="../assets/images/list-close.png"
            alt=""
            class="w100 "
          />
        </div>
      </div>
      <div
        :class="[isOpen ? 'show' : 'hide']"
        class="header-list m-list-wrap   "
        @touchmove.prevent
      >
        <div class="header-cell dfc cross-start ">
          <div class=" header-top dfc   w100">
            <!-- 公寓 -->
            <div class="lang-wrap m-list-item top pointer">
              <el-dropdown trigger="click" class=" ">
                <div
                  :class="[site ? 'fctheme' : 'fc333']"
                  class="el-dropdown-link dfr flex-center  "
                >
                  <div
                    :class="[site ? 'fctheme' : 'fc333']"
                    class="pc-name fs16 "
                  >
                    {{ langText.apartmentList }}
                  </div>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu v-if="apartmentList.length" slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in apartmentList"
                    :key="index"
                    @click.native="goApartmentList(item)"
                  >
                    <span
                      :class="[site == item.site_name ? 'fctheme' : '']"
                      v-if="lang == 'CN'"
                      >{{ item.site_name }}</span
                    >
                    <span
                      :class="[site == item.en_name ? 'fctheme' : '']"
                      v-else
                      >{{ item.en_name }}</span
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- 服务 -->
            <div class="w100 lang-wrap pointer m-list-item">
              <el-dropdown trigger="click" class=" h100">
                <div class="el-dropdown-link dfr flex-center w100 h100">
                  <div class="pc-name fs16 fc333">
                    {{ langText.service }}
                  </div>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in serviceList"
                    :key="index"
                    @click.native="goService(item.url)"
                  >
                    <span v-if="lang == 'CN'">{{ item.name }}</span>
                    <span v-else>{{ item.en_name }}</span></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div
              v-for="(item, index) in headerList"
              :key="index"
              @click="changeHeader(index)"
              class="h100 text-left m-list-item"
            >
              <div
                v-if="index <= 2"
                class="header-item  por h100 dfr pointer "
                :class="[item.active ? 'active' : '']"
              >
                {{ item.title }}
                <div
                  :class="[item.active ? 'active' : '', 'border-line']"
                ></div>
              </div>
            </div>
          </div>
          <div class="dfc w100 dfc cross-start w100">
            <!-- 语言 -->
            <div class="w100 lang-wrap pointer m-list-item">
              <el-dropdown trigger="click" class=" h100">
                <div class="el-dropdown-link dfr flex-center w100 h100">
                  <div class="pc-name fs16 fc333">
                    {{ langText.language }}
                  </div>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="changeLang('EN')"
                    >EN</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="changeLang('CN')"
                    >中文</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- 登录 个人信息 -->
            <div v-if="isLogin" class="w100 m-list-item  ">
              <div class="login-box dfr flex-center pointer fs16 fc000 por">
                <el-dropdown class="phone-drop " trigger="click">
                  <div class="el-dropdown-link w100 h100 dfr flex-center">
                    <div class="m-name-box">
                      {{ langText.hello }}{{ userInfo.name }}
                    </div>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="goInfo">
                      {{ langText.userInfo }}</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="goOrderLink">{{
                      langText.order
                    }}</el-dropdown-item>
                    <el-dropdown-item @click.native="logoutHandle">{{
                      langText.logout
                    }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div
              v-else
              class="go-login w100 m-list-item dfr cross-start fs16 fc000"
            >
              <div
                @click="showLoginHandle"
                class="go-sign-box dfr flex-center pointer"
              >
                <span v-if="lang == 'CN'">登录、注册</span>
                <span v-else>Sign In</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { windowRize, logOut } from "../utils/util";
export default {
  props: {
    activeNum: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isOpen: false,
      orderLink: "http://www.allsethomeus.com/#/home", // 订单历史 跳转到shopify对应界面
      apartmentList: [],
      // 服务 跳转到shopify对应界面
      serviceList: [
        {
          link: "http://www.allsethomeus.com/#/home",
          name: "deposit" //寄存
        },
        {
          link: "http://www.allsethomeus.com/#/home",
          name: "pickUp" //接机
        },
        {
          link: "http://www.allsethomeus.com/#/home",
          name: "moveIn" //入住
        }
      ],
      // 商城，关于我们 跳转到shopify对应界面
      headerList: [
        {
          title: "About Us",
          active: false,
          activeNum: "4",
          hash: "",
          targetLink: "http://www.allsethomeus.com/#/home"
        },
        {
          title: "Contact Us",
          active: false,
          activeNum: "5",
          hash: "contactUs"
        }
      ],

      pcWidth: true,
      isRouterList: false,
      isLogin: false,
      userInfo: {
        avatar: require("../assets/images/avatar.png"),
        name: ""
      },
      lang: "EN",
      langText: {
        home: "home",
        apartmentList: "apartmentList",
        hello: "hello,",
        service: "Service",
        contactUs: "Contact Us",
        aboutUs: "About Us",
        shop: "Shop,",
        language: "Language",
        login: "Sign In / Sign Up"
      },
      site: "",
      showList: false
    };
  },
  watch: {
    activeNum(number) {
      this.initNumber(number);
    }
  },
  created() {
    this.initTags();
    this.initShopLink();
  },
  mounted() {
    this.initLang();
    this.$bus.$on("changeLang", () => {
      this.initLang();
    });
    this.initPage();
    this.$bus.$on("updateInfo", () => {
      this.initUser();
    });
    this.$bus.$on("logout", () => {
      let isLogin = this.$localStore.get("isLogin");
      if (isLogin) {
        this.logoutHandle();
      } else {
        if (this.$route.name == "me") {
          this.$go("home");
        }
      }
      this.isOpen = false;
    });
    this.initRize();
    this.initNumber(this.activeNum);
  },
  methods: {
    showListHandle() {
      this.showList = true;
    },
    goAccountInfo() {
      this.$go("me");
      this.isOpen = false;
    },
    goOrderLink() {
      window.open(this.orderLink, "_self");
      this.isOpen = false;
    },
    saveStoreFilter() {
      const data = {
        keyword: "",
        minPrice: "",
        maxPrice: "",
        currentBeds: "",
        site: "",
        distanceSort: 2,
        unShowMap: true
      };
      this.$localStore.set("filterData", data);
    },
    goApartmentList(item) {
      let _tag = item.en_name;
      if (this.lang == "CN") {
        _tag = item.site_name;
      }

      if (_tag == "no choose" || _tag == "默认") {
        _tag = "";
      }
      this.site = _tag;

      let filterData = this.$localStore.get("filterData") || "";
      if (!filterData) {
        this.saveStoreFilter();
      } else {
        filterData.site = _tag;

        this.$localStore.set("filterData", filterData);
      }
      if (this.$route.name == "apartmentList") {
        this.$bus.$emit("initApartmentList");
      } else {
        this.$go("apartmentList");
      }
      this.isOpen = false;
    },
    async initTags() {
      const resp = await this.$API
        .post("apartmentTag", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        const data = {
          en_name: "no choose",
          site_name: "默认"
        };
        this.apartmentList = [data, ...resp.data.sites];
      }
    },
    async initServiceList() {
      const resp = await this.$API
        .post("serviceList", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.serviceList = resp.data.menus;
      }
    },
    async initShopLink() {
      const resp = await this.$API
        .post("shopLink", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.headerList[0].targetLink = resp.data.shop_url[0].shop_url;
      }
    },
    goService(link) {
      window.open(link, "_self");
      this.isOpen = false;
    },
    initLang() {
      let lang = this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.header;
      } else if (lang == "CN") {
        this.langText = this.$CN.header;
      }
      this.lang = lang;
      // this.headerList[0].title = this.langText.apartmentList;
      // this.headerList[1].title = this.langText.service;
      this.headerList[0].title = this.langText.aboutUs;
      this.headerList[1].title = this.langText.contactUs;

      let filterData = this.$localStore.get("filterData") || "";
      if (!filterData) {
        this.saveStoreFilter();
      } else {
        let site = filterData.site;

        if (this.lang == "CN") {
          this.apartmentList.forEach(item => {
            if (item.en_name == site) {
              site = item.site_name;
            }
          });
        } else {
          this.apartmentList.forEach(item => {
            if (item.site_name == site) {
              site = item.en_name;
            }
          });
        }
        if (site == "no choose" || site == "默认") {
          site = "";
        }
        this.site = site;
        filterData.site = site;

        this.$localStore.set("filterData", filterData);
      }
    },
    changeLang(lang) {
      this.$localStore.set("lang", lang);
      this.$bus.$emit("changeLang");
      this.lang = lang;
    },
    initNumber(number) {
      if (number) {
        this.headerList.map(item => {
          if (item.activeNum == number) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
      } else {
        this.headerList.map(item => {
          item.active = false;
          return item;
        });
      }
    },
    goInfo() {
      this.$go("me");
      this.isOpen = false;
    },
    logoutHandle() {
      this.isLogin = false;
      logOut();
      if (this.$route.name == "me") {
        this.$go("home");
      }
    },
    initRize() {
      const handleFun = () => {
        this.pcWidth = window.innerWidth > 768 ? true : false;
      };
      handleFun();
      // 监听窗口变化已防抖
      windowRize(handleFun);

      let body = document.querySelector("body");
      body.addEventListener(
        "click",
        () => {
          if (this.isRouterList) {
            this.isRouterList = false;
          }
        },
        true
      );
    },
    showLoginHandle() {
      this.$bus.$emit("showLogin", "sign");
    },
    showSignHandle() {
      this.$bus.$emit("showLogin", "account");
    },
    initPage() {
      this.initServiceList();
      this.headerList.forEach(item => {
        item.active = false;
        if (this.activeNum == item.activeNum) {
          item.active = true;
        }
      });
      this.initUser();
    },
    async initUser() {
      const resp = await this.$API.post("userInfo").then(resp => resp);

      if (resp.code == 1000) {
        this.userInfo.name = `${resp.data.info.first_name} ${resp.data.info.last_name}`;
        this.$localStore.set("userId", resp.data.info.id);

        this.isLogin = true;
      }
    },
    go(hash) {
      this.isRouterList = false;
      this.$go(hash);
      this.isOpen = false;
    },
    changeHeader(index) {
      if (this.headerList[index].targetLink) {
        window.open(this.headerList[index].targetLink, "_self");
        return;
      }
      this.headerList.map(item => {
        item.active = false;
      });
      this.headerList[index].active = true;
      this.go(this.headerList[index].hash);
      this.isOpen = false;
    },
    showRouterList() {
      this.isRouterList = !this.isRouterList;
    }
  }
};
</script>

<style lang="scss" scoped>
.phone-header-top {
  margin-left: 20px;
}
.header-top {
  margin-bottom: 8px;
}
.m-list-wrap .lang-wrap {
  margin-left: 0;
}
.lang-wrap {
  margin-left: 40px;
}
.pc-name {
  white-space: nowrap;
}
.app-header {
  position: sticky;
  top: 0;
  z-index: 90;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  height: 80px;
  box-sizing: border-box;
  overflow: hidden;
}
.phone-header {
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0;
  height: 100%;
  .header-list {
    .header-item {
      margin-right: 20px;
      &.active {
        color: #87bcdc;
      }
      .border-line {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        bottom: 0;
        height: 3px;
        background: #87bcdc;
        transition: all 0.3s;
        transform-origin: center;
        &.active {
          width: 100%;
        }
      }
    }
  }
  .login-box {
    margin-left: 0;
    height: 100%;
    width: 120px;
    .m-name-box {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .avatar-box {
      margin-right: 0;
    }
  }
  .go-login {
    margin-left: 0;
  }
}
.pc-header {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
  height: 100%;
  .img-logo {
    width: 191px;
  }
  .header-list {
    .header-item {
      margin-left: 40px;
      &.active {
        color: #87bcdc;
      }
      .border-line {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        bottom: 0;
        height: 3px;
        background: #87bcdc;
        transition: all 0.3s;
        transform-origin: center;
        &.active {
          width: 100%;
        }
      }
    }
    .search {
      margin-left: 50px;
    }
  }
}
.login-box {
  margin-left: 40px;
  .avatar-box {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
  }
  .phone-drop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
.go-login {
  margin-left: 40px;
  .go-login-box {
    transition: all 0.3s;
    &:hover {
      color: #529dcb;
      .login-img {
        background: url("../assets/images/password-active.png") no-repeat;
        background-size: 16px 16px;
      }
    }
    .login-img {
      width: 16px;
      height: 16px;
      background: url("../assets/images/login-password.png") no-repeat;
      background-size: 16px 16px;
      margin-right: 9px;
      transition: all 0.3s;
    }
  }
  .go-sign-box {
    height: 40px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 0 18px;
    &:hover {
      color: #fff;
      background: #529dcb;
      border: 1px solid #529dcb;
      .sign-img {
        background: url("../assets/images/arrow-fff.png") no-repeat;
        background-size: 16px 12px;
      }
    }
    .sign-img {
      width: 16px;
      height: 12px;
      background: url("../assets/images/arrow-right.png") no-repeat;
      background-size: 16px 12px;
      margin-right: 9px;
    }
  }
}
.list-cell {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  border-radius: 4px;
  .list-item {
    padding: 20px;
    border-bottom: 1px solid #eee;
    &:last-child {
      border: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  .app-header {
    height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .lang-wrap {
    margin-left: 0;
    height: 100%;
  }
  .go-sign-box {
    padding: 0 6px !important;
    font-size: 12px;
    height: 34px !important;
  }
  .phone-header .header-list .header-item {
    margin-right: 6px;
  }
  .app-header {
    height: 50px;
    overflow: unset;
  }
  .phone-header .header-list .header-item .border-line.active {
    width: 0;
  }
  .header-top {
    margin-bottom: 0;
  }
}
.mobile-wrap-header .img-logo {
  width: 110px;
}
.list-box {
  width: 24px;
}
.m-list-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
  z-index: 9;
  background: #fff;
  overflow: hidden;
  transform: translateX(100%);
  transition: all 0.3s ease;
  padding-left: 20px;
  box-sizing: border-box;
  &.show {
    transform: translateX(0);
  }
  &.hide {
    transform: translateX(100%);
  }
}
.m-list-item {
  border-top: 1px solid #eee;
  padding: 20px 0;
  &.top {
    border: none;
  }
}
.m-name-box {
  font-size: 16px;
  color: #333;
}
</style>
